/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { ChunkLUT, Colors, Dropdown, NumberInput, presets, TextInput } from '@steadybit/ui-components-lib';
import { ReactElement } from 'react';

import VariablesAndPlaceholders from './VariablesAndPlaceholders';
import useChunkLUT from './useChunkLUT';

interface IntegerProps {
	value: string | number;
	hasErrors?: boolean;
	disabled: boolean;
	min?: number;
	max?: number;
	setValue: (value: string | number) => void;
}

export default function Integer({ value, min, max, hasErrors, disabled, setValue }: IntegerProps): ReactElement {
	return (
		<Input
			value={value}
			onChange={(_v) => {
				if (typeof _v === 'string') {
					return setValue(_v);
				}
				setValue(_v >= 0 ? _v : 0);
			}}
			hasErrors={hasErrors}
			disabled={disabled}
			min={min}
			max={max}
		/>
	);
}

interface InputProps {
	onChange: (value: number | string) => void;
	value: number | string;
	hasErrors?: boolean;
	withUnit?: boolean;
	disabled: boolean;
	min?: number;
	max?: number;
}

export function Input({
	max = Number.MAX_SAFE_INTEGER,
	min = 0,
	hasErrors,
	disabled,
	withUnit,
	value,
	onChange,
}: InputProps): ReactElement {
	const chunkLUT: ChunkLUT = useChunkLUT();

	return (
		<Dropdown
			renderDropdownContent={({ width, close }) => (
				<presets.dropdown.DropdownContentFrame width={width}>
					<VariablesAndPlaceholders
						width={width}
						selectItem={(v) => {
							onChange(v);
							close();
						}}
					/>
				</presets.dropdown.DropdownContentFrame>
			)}
		>
			{({ setRefElement, isOpen, setOpen }) => {
				let valueAsNumber = Number(value);
				if (isNaN(valueAsNumber)) {
					valueAsNumber = 0;
				}
				const hasError = hasErrors || valueAsNumber < min || valueAsNumber > max;

				return (
					<div
						ref={setRefElement}
						onFocus={() => {
							if (!disabled) {
								setOpen(true);
							}
						}}
						style={{ width: '100%', paddingRight: withUnit && isOpen ? '1px' : undefined }}
					>
						{isOpen ? (
							<NumberInput
								value={valueAsNumber}
								disabled={disabled}
								errored={hasError}
								highlighted
								min={min}
								max={max}
								autoFocus
								onChange={(_v) => {
									if (typeof _v !== 'number' || Number.isNaN(_v)) {
										_v = 0;
									}
									onChange(_v);
								}}
								data-cy="text-field-with-highlighting"
							/>
						) : (
							<TextInput
								highlightedChunkLUT={chunkLUT}
								value={String(value)}
								disabled={disabled}
								errored={hasError}
								onClick={() => setOpen(true)}
								onFocus={() => setOpen(true)}
								style={
									withUnit
										? {
												borderTopRightRadius: 0,
												borderBottomRightRadius: 0,
												borderRight: hasErrors ? '1px solid ' + Colors.coral : 'none',
											}
										: undefined
								}
								data-cy="text-field-with-highlighting"
							/>
						)}
					</div>
				);
			}}
		</Dropdown>
	);
}
