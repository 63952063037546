/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Code,
	Container,
	Label,
	Link,
	ModalContentV2,
	ModalHeaderV2,
	ModalOverlay,
	ModalV2,
	Stack,
	Text,
	Tooltip,
} from 'components';
import { ErrorMessage, Flex, TextInput } from '@steadybit/ui-components-lib';
import { IconInformationCircle } from 'components/icons';
import { Formik, useFormikContext } from 'formik';
import { getBaseHref } from 'utils/getBaseHref';
import { useTenant } from 'tenancy/useTenant';
import { ReactElement } from 'react';

import CodeTypeSelection, { codeTypeOptions } from './CodeTypeSelection';
import ExperimentTags from '../../experimentsV2/ExperimentTags';
import ScaleSelection, { scaleOptions } from './ScaleSelection';
import { useTeam } from '../../../services/useTeam';

type TagBadgeModalProps = {
	onClose: () => void;
};

type TagBadgeValues = {
	codeType: string;
	caption: string;
	tags: string[];
	teamId: string;
	scale: string;
};

export default function TagBadgeModal({ onClose }: TagBadgeModalProps): ReactElement {
	const team = useTeam();
	return (
		<ModalOverlay open onClose={onClose}>
			{({ close }) => (
				<ModalV2 maxWidth="700px">
					<ModalHeaderV2 title={'Create a Tag Badge'} px="large" onClose={close} />
					<ModalContentV2 px="large">
						<Formik
							initialValues={{
								tags: [],
								teamId: team.id,
								codeType: codeTypeOptions[0].id,
								scale: scaleOptions[0].id,
								caption: 'Create Experiment for Tag',
							}}
							onSubmit={() => {}}
						>
							<TagBadgeForm />
						</Formik>
					</ModalContentV2>
				</ModalV2>
			)}
		</ModalOverlay>
	);
}

function TagBadgeForm(): ReactElement {
	const formik = useFormikContext<TagBadgeValues>();
	const { tags, codeType, caption, scale } = formik.values;
	const tag = tags.length > 0 ? tags[0] : undefined;
	const tenant = useTenant();

	const imageUrl = `${getBaseHref()}api/badges/linked-badge.svg?tenantKey=${tenant.key}&scale=${scale}&tag=${tag}&createCaption=${encodeURI(`${caption} ${tag}`)}`;
	const linkUrl = `${getBaseHref()}api/badges/link?tenantKey=${tenant.key}&tag=${tag}`;
	const html = `<a href='${linkUrl}' target='_blank'><img alt='${tag}' src='${imageUrl}'></a>`;
	const markdown = `[![${tag}](${imageUrl})](${linkUrl})`;

	const isTagTouched = formik.getFieldMeta('tags').touched;

	return (
		<Stack size={'medium'}>
			<Text muted>
				You can create a badge for any experiment tag. It will automatically update to reflect the current state. Refer
				to our{' '}
				<Link href={'https://docs.steadybit.com/integrate-with-steadybit/badges#tag-badges'} external>
					documentation
				</Link>{' '}
				for the details.
				<br />
				<br />
				Provide the tag that will be automatically added to new experiments created via the badge and be used to
				identify any existing experiment the badge should refer to.
			</Text>

			<Flex spacing="xSmall">
				<Label>Choose a Tag to select the corresponding experiments to show via the badge</Label>
				<ExperimentTags
					hasError={!tag && isTagTouched}
					minWidth="250px"
					maxWidth="100%"
					initiallyOpen
					maxTags={1}
					showTags
				/>
				{!tag && (
					<ErrorMessage withIcon type="small">
						Selecting a tag is necessary in order to create the badge
					</ErrorMessage>
				)}
			</Flex>

			<Flex direction="horizontal" spacing="medium">
				<Flex spacing="xxxSmall">
					<Text variant="small">Format</Text>
					<CodeTypeSelection
						selectedCodeType={codeType}
						selectCodeType={(codeType) => formik.setFieldValue('codeType', codeType)}
					/>
				</Flex>

				<Flex spacing="xxxSmall">
					<Text variant="small">Scale</Text>
					<ScaleSelection selectedScale={scale} setSelectScale={(scale) => formik.setFieldValue('scale', scale)} />
				</Flex>

				<Flex spacing="xxxSmall" style={{ width: '100%' }}>
					<Flex direction="horizontal" align="center" spacing="xxSmall">
						<Text variant="small">Call to action</Text>
						<Tooltip
							color="light"
							content={
								<Text variant="small" maxWidth="25em">
									The call to action will be shown when no experiment with the tag exists
								</Text>
							}
						>
							<IconInformationCircle variant="small" color="neutral500" />
						</Tooltip>
					</Flex>
					<TextInput
						size="small"
						value={caption}
						onChange={(v) => formik.setFieldValue('caption', v)}
						style={{ width: '100%' }}
					/>
				</Flex>
			</Flex>

			{tag && (
				<Stack variant={'xSmall'} sx={{ gridColumn: 'span 2' }}>
					<Label>Result</Label>
					<Stack variant={'xSmall'}>
						<Container>
							<Link href={linkUrl} target={'_blank'}>
								<img src={imageUrl} key={`example-badge-${codeType}`} alt={'Experiment Tag Badge'} />
							</Link>
						</Container>
						<Code
							lang={codeType === 'HTML' || codeType === 'MARKDOWN' ? 'markdown' : 'jsx'}
							sx={{ whiteSpace: 'normal' }}
							withCopyToClipboard
						>
							{codeType === 'HTML' ? html : codeType === 'MARKDOWN' ? markdown : imageUrl}
						</Code>
					</Stack>
				</Stack>
			)}
		</Stack>
	);
}
