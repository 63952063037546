/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { getColorForState } from 'pages/experiments/components/utils';
import { Bars, Colors, metrics } from '@steadybit/ui-components-lib';
import { ActionsPageModel, BarChartDataVO, RollupVO } from 'ui-api';
import { ChartType } from 'pages/settings/Reporting/urlParams';
import { toTitleCase } from 'utils/string';
import { days } from 'utils/time';
import axios from 'axios';

export interface ReportingResponse extends ActionsPageModel<Bars> {
	content: Bars[];
}

interface ReportingResponseFromBackend extends ActionsPageModel<BarChartDataVO> {
	content: BarChartDataVO[];
}

export class ReportingApi {
	public async getExecutions({
		environmentIds = [],
		experimentKeys = [],
		teamIds = [],
		type,
		time,
	}: {
		time: string | [number, number] | undefined;
		environmentIds?: string[];
		experimentKeys?: string[];
		teamIds?: string[];
		type: ChartType;
	}): Promise<ReportingResponse> {
		const params = new URLSearchParams();

		if (teamIds.length > 0) {
			params.append('teamIds', teamIds.join(','));
		}
		if (environmentIds.length > 0) {
			params.append('environmentIds', environmentIds.join(','));
		}
		if (experimentKeys.length > 0) {
			params.append('experimentKeys', experimentKeys.join(','));
		}

		let rollup: RollupVO = 'MONTHLY';

		// all time
		let from: number | undefined = undefined;
		let to: number = new Date().getTime();

		// custom
		if (Array.isArray(time)) {
			from = time[0];
			to = time[1];
			rollup = to - from < days(31).getMillis() ? 'DAILY' : 'MONTHLY';
		}

		// last 30 days
		if (time === 'last30Days') {
			const yesterday = new Date();
			yesterday.setDate(yesterday.getDate() - 1);
			to = yesterday.getTime();
			from = to - days(30).getMillis();
			rollup = 'DAILY';
		}

		if (from) {
			params.append('from', toBackendLocaleString(new Date(from)));
		}
		params.append('to', toBackendLocaleString(new Date(to)));
		params.append('rollup', rollup);

		const typeToPath: Record<ChartType, string> = {
			EXPERIMENT_NOTHING: 'experiments/reports/creations',
			EXPERIMENT_CREATED: 'experiments/reports/creations/created-via',
			EXPERIMENT_ORIGIN: 'experiments/reports/creations/origin',
			EXECUTION_NOTHING: 'experiments/reports/executions',
			EXECUTION_ACTION: 'experiments/reports/executions/action',
			EXECUTION_STATE: 'experiments/reports/executions/state',
			EXECUTION_TRIGGER: 'experiments/reports/executions/trigger',
			EXECUTION_TRANSITION_FC: 'experiments/reports/executions/transitions',
			EXECUTION_TRANSITION_CF: 'experiments/reports/executions/transitions',
			EXECUTION_COMPLETED_VS_FAILED: 'experiments/reports/executions/state',
			TEAMS: 'reports/teams',
			USERS: 'reports/users',
			ENVIRONMENTS: 'reports/environments',
		};

		if (type === 'EXECUTION_TRANSITION_FC') {
			params.append('fromStates', 'FAILED');
			params.append('toStates', 'COMPLETED');
		} else if (type === 'EXECUTION_TRANSITION_CF') {
			params.append('fromStates', 'COMPLETED');
			params.append('toStates', 'FAILED');
		}

		const result = (await axios.get<ReportingResponseFromBackend>(`/ui/${typeToPath[type]}`, { params })).data;

		return {
			...result,
			content: postProcessData(result.content, type),
		};
	}
}

function toBackendLocaleString(date: Date): string {
	const year = date.getFullYear();
	let month = '';
	if (date.getMonth() + 1 < 10) {
		month = `0${date.getMonth() + 1}`;
	} else {
		month = `${date.getMonth() + 1}`;
	}

	const day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
	return `${year}-${month}-${day}`;
}

function postProcessData(data: BarChartDataVO[], type: ChartType): Bars[] {
	const colors = new Map<string, string>();
	if (type === 'EXECUTION_STATE' || type === 'EXECUTION_COMPLETED_VS_FAILED') {
		colors.set('Created', getColorForState('CREATED'));
		colors.set('Prepared', getColorForState('PREPARED'));
		colors.set('Running', getColorForState('RUNNING'));
		colors.set('Failed', getColorForState('FAILED'));
		colors.set('Completed', getColorForState('COMPLETED', Colors.feedbackSuccess));
		colors.set('Canceled', getColorForState('CANCELED'));
		colors.set('Skipped', getColorForState('SKIPPED'));
		colors.set('Errored', getColorForState('ERRORED'));

		if (type === 'EXECUTION_COMPLETED_VS_FAILED') {
			data = data.map((bucket) => {
				const completed = bucket.values.COMPLETED || 0;
				const failed = bucket.values.FAILED || 0;
				const total = completed + failed;
				console.log({ total, completed, failed });

				if (total > 0) {
					const COMPLETED = ((completed / total) * 100) | 0;
					bucket.values = {
						// values in percent
						COMPLETED,
						FAILED: 100 - COMPLETED,
					};
				} else {
					bucket.values = {};
				}
				return bucket;
			});
		}
	}

	let colorIndex = 0;
	return data.map(({ date, values }) => {
		return {
			domain: String(date),
			metrics: Object.entries(values)
				.map(([key, value]) => {
					const resolvedLabel = key === '*' ? '' : resolveLabel(key, type);
					if (!colors.has(resolvedLabel)) {
						colors.set(resolvedLabel, metrics.BarChartColorTheme[colorIndex++ % metrics.BarChartColorTheme.length]);
					}

					return { label: resolvedLabel, value, color: colors.get(resolvedLabel) };
				})
				.filter(({ value }) => value > 0),
		};
	});
}

function resolveLabel(label: string, type: ChartType): string {
	if (type === 'EXECUTION_TRIGGER') {
		return TRIGGER_LABELS.get(label) || toTitleCase(label.toLocaleLowerCase());
	}
	if (type === 'EXECUTION_STATE' || type === 'EXECUTION_COMPLETED_VS_FAILED' || type === 'EXPERIMENT_CREATED') {
		if (label === 'UI') {
			return 'UI';
		}
		return toTitleCase(label.toLocaleLowerCase());
	}
	if (type === 'EXPERIMENT_ORIGIN') {
		return ORIGIN_LABELS.get(label) || toTitleCase(label.toLocaleLowerCase());
	}
	return label;
}

const TRIGGER_LABELS = new Map<string, string>(
	Object.entries({
		API: 'API',
		CLI: 'CLI',
		UI: 'UI',
	}),
);

const ORIGIN_LABELS = new Map<string, string>(
	Object.entries({
		FROM_SCRATCH: 'From scratch',
	}),
);
