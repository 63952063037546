/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import StripChart, { colors, DataPoint as StripChartDataPoint, StripType } from 'components/StripChart/StripChart';
import { DataStreamResult } from 'utils/hooks/stream/result';
import { Colors, Text } from '@steadybit/ui-components-lib';
import { StateOverTimeWidgetVO } from 'ui-api';
import { Container } from 'components';
import { ReactElement } from 'react';

import { ExperimentRunLogsAndMetricsProps } from '../../experimentExecutionLogsAndMetrics';
import ChartWithTimeAxis from '../../metrics/ChartWithTimeAxis';
import LoadingContent from '../../metrics/LoadingContent';
import ExperimentRunCard from '../../ExperimentRunCard';
import EmptyContent from '../../metrics/EmptyContent';
import StripChartTime from '../StripChartTime';

export interface StateOverTimeMetric {
	dataPoints: DataPoint[];
	label: string;
	id: string;
}

export interface DataPoint {
	tooltip: string;
	value?: number;
	state: string;
	url?: string;
	from: number;
	to: number | undefined;
}

interface StateOverTimeCardPresenterProps
	extends Pick<ExperimentRunLogsAndMetricsProps, 'position' | 'start' | 'duration'> {
	metrics: DataStreamResult<StateOverTimeMetric[]>;
	widget: StateOverTimeWidgetVO;
	ended: boolean;
}

export default function StateOverTimeCardPresenter({
	position,
	duration,
	metrics,
	widget,
	start,
	ended,
}: StateOverTimeCardPresenterProps): ReactElement {
	let content: ReactElement;

	if (metrics.loading) {
		// Note: The loading prop has a confusing name/behavior. loading={ended is intentional. Also see the
		// Storybook file on it.
		content = <LoadingContent loading={ended}>Waiting for metrics...</LoadingContent>;
	} else if (metrics.error != null) {
		content = (
			<EmptyContent>
				<Text style={{ color: Colors.coral }}>Failed to gather metrics: {metrics.error.message}</Text>
			</EmptyContent>
		);
	} else if (metrics.value.length === 0) {
		content = <EmptyContent>No metrics recorded.</EmptyContent>;
	} else {
		content = (
			<Container
				display="flex"
				flexDirection="column"
				justifyContent="flex-end"
				p="small"
				py="xSmall"
				height="100%"
				overflowY="auto"
			>
				<ChartWithTimeAxis position={position} start={start} duration={duration}>
					<StripChart
						fromTimestamp={start}
						toTimestamp={Math.max(start + duration, position ?? 0)}
						strips={metrics.value.map((m) => {
							const strip = {
								id: m.id,
								label: m.label,
								dataPoints: m.dataPoints.map((d) => ({
									// eslint-disable-next-line @typescript-eslint/no-explicit-any
									color: (colors as any)[d.state],
									timestamp: d.from,
									endTimestamp: d.to,
									url: d.url,
									tooltip: d.tooltip,
									values: {},
								})),
							};
							fillGaps(strip);
							return strip;
						})}
						getTooltipContent={(_, d) => <TooltipContent dataPoint={d} />}
					/>
				</ChartWithTimeAxis>
			</Container>
		);
	}

	return <ExperimentRunCard title={widget.title}>{content}</ExperimentRunCard>;
}

function fillGaps(strip: StripType): void {
	for (let i = strip.dataPoints.length - 2; i >= 0; i--) {
		strip.dataPoints[i].endTimestamp = strip.dataPoints[i + 1].timestamp - 1;
	}
}

function TooltipContent({ dataPoint }: { dataPoint: StripChartDataPoint }): ReactElement {
	const content = dataPoint.tooltip || '';
	const lines = content.split('\n');
	if (lines.length <= 1) {
		return (
			<>
				<StripChartTime dataPoint={dataPoint} />
				<span>{content}</span>
			</>
		);
	}
	return (
		<>
			<StripChartTime dataPoint={dataPoint} />
			<ul style={{ listStyle: 'none', padding: '0 12px' }}>
				{lines.map((line, i) => (
					<li key={i}>{line}</li>
				))}
			</ul>
		</>
	);
}
