/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Button,
	Container,
	ModalContentV2,
	ModalHeaderV2,
	ModalOverlay,
	ModalV2,
	RouterButtonIcon,
	RouterLink,
	Snackbar,
	Stack,
	Table,
	TableBody,
	TableDataCell,
	TableHead,
	TableHeadCell,
	TableRow,
	Text,
	Toggle,
	Tooltip,
} from 'components';
import { UseTemplateFormData } from 'pages/templates/UseTemplateModal/UseTemplateFormLoadingHandler';
import UseTemplateActionsChecker from 'pages/templates/UseTemplateModal/UseTemplateActionsChecker';
import React, { ReactElement, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import NavigationWithContent from 'pages/templates/UseTemplateModal/pages/NavigationWithContent';
import { createExperimentRequestFromTemplate } from 'pages/templates/UseTemplateModal/utils';
import { useEnvironments, UseEnvironmentsResult } from 'utils/hooks/useEnvironments';
import { usePromiseWithReExecution } from 'utils/hooks/usePromiseWithReExecution';
import LoadingIndicator from 'components/LoadingIndicator/LoadingIndicator';
import { AdviceValidationStateVO, TeamSummaryVO, TemplateVO } from 'ui-api';
import Collapsible from 'components/Collapsible.v2/Collapsible.v2';
import useGlobalPermissions from 'services/useGlobalPermissions';
import { useEventEffect } from 'utils/hooks/useEventEffect';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import StateBadge from 'components/StateBadge/StateBadge';
import { useDimensions } from 'utils/hooks/useDimensions';
import { useLicenseFeature } from 'services/licenseApi';
import Markdown from 'components/Markdown/Markdown';
import { usePromise } from 'utils/hooks/usePromise';
import { formatDateWithTime } from 'utils/dateFns';
import { IconExperiment } from 'components/icons';
import { PageParams } from 'utils/hooks/usePage';
import { Services } from 'services/services';
import { useTeam } from 'services/useTeam';
import { debounce } from 'lodash';

interface TargetAdviceTableProps {
	environmentId: string | null;
	adviceDefinitionId: string;
	targetReference: string;
	type: string;
}

export default function TargetAdviceTable({
	adviceDefinitionId,
	targetReference,
	environmentId,
	type,
}: TargetAdviceTableProps): ReactElement | null {
	const [validationsPerformedCollapsed, setValidationsPerformedCollapsed] = useState(true);
	const [reloadSignal, setReloadSignal] = useState(0);
	const adviceValidationsResult = usePromise(
		() => Services.adviceApi.getAdviceValidations(type, targetReference, adviceDefinitionId),
		[adviceDefinitionId, reloadSignal],
	);
	const [lastStableValidations, setLastStableValidations] = useState<AdviceValidationStateVO[]>([]);

	useEventEffect(
		(e) => {
			if (lastStableValidations.map((e) => e.experimentKey).includes(e.experimentKey)) {
				setReloadSignal(reloadSignal + 1);
			}
		},
		['experiment.deleted'],
		() => {},
		[adviceDefinitionId, reloadSignal, lastStableValidations],
	);

	useEffect(() => {
		if (adviceValidationsResult.value) {
			setLastStableValidations(adviceValidationsResult.value);
		}
	}, [adviceValidationsResult.value]);

	useEffect(() => {
		setValidationsPerformedCollapsed(true);
	}, [adviceDefinitionId]);

	if (lastStableValidations.length === 0) {
		return null;
	}

	if (adviceValidationsResult.loading) {
		return <LoadingIndicator variant="small" />;
	} else if (adviceValidationsResult.value?.every((validationResult) => validationResult.validated)) {
		return (
			<Collapsible
				title="Validations Performed"
				onCollapseChange={(collapsed) => setValidationsPerformedCollapsed(collapsed)}
				initialExpanded={!validationsPerformedCollapsed}
			>
				<Stack size="small">
					<TargetAdviceValidationTable
						reload={() => setReloadSignal(reloadSignal + 1)}
						adviceDefinitionId={adviceDefinitionId}
						validations={lastStableValidations}
						targetReference={targetReference}
						environmentId={environmentId}
						type={type}
					/>
				</Stack>
			</Collapsible>
		);
	}
	return (
		<Stack size="small">
			<Text variant="mediumStrong">Recommended Validations</Text>
			<TargetAdviceValidationTable
				reload={() => setReloadSignal(reloadSignal + 1)}
				adviceDefinitionId={adviceDefinitionId}
				validations={lastStableValidations}
				targetReference={targetReference}
				environmentId={environmentId}
				type={type}
			/>
		</Stack>
	);
}

interface TargetAdviceValidationTableProps {
	validations: AdviceValidationStateVO[];
	environmentId: string | null;
	adviceDefinitionId: string;
	targetReference: string;
	reload: () => void;
	type: string;
}

function TargetAdviceValidationTable({
	adviceDefinitionId,
	targetReference,
	environmentId,
	validations,
	reload,
	type,
}: TargetAdviceValidationTableProps): ReactElement | null {
	const team = useTeam();
	const environments = useEnvironments(environmentId || undefined);
	const templatesEnabled = !!useLicenseFeature('TEMPLATES');

	const [divRef, [tableWidth]] = useDimensions<HTMLDivElement>();

	return (
		<Container ref={divRef}>
			<Table
				sx={{
					'& td, & th': {
						verticalAlign: 'top',
					},
				}}
			>
				<TableHead>
					<TableRow>
						<TableHeadCell width={64}>Validated</TableHeadCell>
						<TableHeadCell width={tableWidth - (240 + 64)}>Validation Name</TableHeadCell>
						<TableHeadCell width={240}></TableHeadCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{validations
						.sort((a, b) => a.id.localeCompare(b.id))
						.map((validation) => {
							if (validation.type === 'EXPERIMENT') {
								return (
									<ExperimentRow
										key={validation.id}
										isDisabled={!templatesEnabled && validation.template}
										adviceDefinitionId={adviceDefinitionId}
										targetReference={targetReference}
										environmentId={environmentId}
										environments={environments}
										validation={validation}
										tableWidth={tableWidth}
										reload={reload}
										type={type}
										team={team}
									/>
								);
							} else if (validation.type === 'TEXT') {
								return (
									<ValidationTextRow
										key={validation.id}
										adviceDefinitionId={adviceDefinitionId}
										targetReference={targetReference}
										validation={validation}
										tableWidth={tableWidth}
										type={type}
									/>
								);
							}
						})}
				</TableBody>
			</Table>
		</Container>
	);
}

interface ExperimentRowProps {
	validation: AdviceValidationStateVO;
	environments: UseEnvironmentsResult;
	environmentId: string | null;
	adviceDefinitionId: string;
	targetReference: string;
	isDisabled: boolean;
	team: TeamSummaryVO;
	tableWidth: number;
	type: string;
	reload: () => void;
}

interface ValidationRowProps {
	validation: AdviceValidationStateVO;
	adviceDefinitionId: string;
	targetReference: string;
	tableWidth: number;
	type: string;
}

function Description(props: { description: string; shortDescription: string }): ReactElement {
	const shortDescriptionRef = useRef<HTMLDivElement>(null);
	const [showReadMore, setShowReadMore] = React.useState<boolean>(false);
	const [renderFullDescription, setRenderFullDescription] = useState(false);

	useLayoutEffect(() => {
		const { current } = shortDescriptionRef;
		const shortDescriptionEllipsed = (current?.offsetWidth ?? 0) < (current?.scrollWidth ?? 0);
		setShowReadMore(shortDescriptionEllipsed || !!props.description);
	}, [props.description, shortDescriptionRef?.current?.scrollWidth, shortDescriptionRef?.current?.offsetWidth]);

	return (
		<>
			{renderFullDescription ? (
				<Stack size="xSmall">
					{props.description ? (
						<Text variant="small" as="span" color="neutral600">
							<Markdown content={props.description} />
						</Text>
					) : (
						<Text variant="small" as="span" color="neutral600">
							{props.shortDescription}
						</Text>
					)}
					<ReadButton onClick={() => setRenderFullDescription(false)}>Read less</ReadButton>
				</Stack>
			) : (
				<Stack direction="horizontal" size="xSmall">
					<Text as="span" variant="small" sx={{ ...textEllipsis, color: 'neutral600' }} ref={shortDescriptionRef}>
						{props.shortDescription}
					</Text>
					{showReadMore ? <ReadButton onClick={() => setRenderFullDescription(true)}>Read more</ReadButton> : null}
				</Stack>
			)}
		</>
	);
}

function ExperimentRow({
	adviceDefinitionId,
	targetReference,
	environmentId,
	environments,
	isDisabled,
	tableWidth,
	validation,
	type,
	team,
	reload,
}: ExperimentRowProps): ReactElement {
	const permissions = useGlobalPermissions();

	const [experimentExecutions, refetch] = usePromiseWithReExecution(
		() =>
			validation.experimentKey
				? Services.experiments.fetchExperimentRuns(
						new URLSearchParams(),
						new PageParams(0, 1),
						validation.experimentKey,
					)
				: Promise.resolve(null),
		[validation.experimentKey],
	);
	const debouncedFetch = useMemo(() => debounce(refetch, 100), [fetch]);
	useEventEffect(
		(e) => {
			if (e.experimentKey === validation.experimentKey) {
				debouncedFetch();
			}
		},
		[
			'experiment.execution.failed',
			'experiment.execution.errored',
			'experiment.execution.canceled',
			'experiment.execution.completed',
		],
		debouncedFetch.cancel,
	);
	const lastRun = experimentExecutions.value?.content[0];

	const [validationTemplate, setValidationTemplate] = useState<TemplateVO>();
	const [loading, setLoading] = useState<boolean>();

	return (
		<>
			{validationTemplate && (
				<ExperimentFromAdviceTemplateForm
					environmentId={environmentId}
					template={validationTemplate}
					onClose={() => {
						setValidationTemplate(undefined);
						setLoading(false);
					}}
					onSubmit={async (formData) => {
						const createExperimentRequest = createExperimentRequestFromTemplate({
							formData: formData,
							teamId: team.id,
						});
						try {
							await Services.adviceApi.createExperimentFromDefinition(
								createExperimentRequest,
								type,
								targetReference,
								adviceDefinitionId,
								validation.id,
								team.id,
								environments.selectedEnvironment?.id || environments.defaultSelectedEnvironment?.id,
							);
							reload();
						} catch (e) {
							const statusCode = e?.response?.status;
							if (statusCode === 403) {
								Snackbar.error('You are not allowed to create this experiment', {
									toastId: 'create-advice-experiment-error',
								});
							} else {
								Snackbar.error('Experiment could not be created', {
									toastId: 'create-advice-experiment-error',
								});
							}
						} finally {
							setValidationTemplate(undefined);
							setLoading(false);
						}
					}}
				/>
			)}
			<TableRow key={validation.id} hoverable={true}>
				<TableDataCell justifyContent="center">
					<Toggle
						checked={validation.validated}
						onChange={async (e) => {
							await Services.adviceApi.updateAdviceExperimentState(type, targetReference, adviceDefinitionId, {
								...validation,
								validated: e.target.checked,
							});
						}}
					/>
				</TableDataCell>
				<TableDataCell maxWidth={tableWidth - 350}>
					<Stack size="none" py="xSmall">
						<ExperimentWrapper experimentKey={validation.experimentKey}>
							<>
								{validation.experimentKey && (
									<>
										<Text as="span" variant="mediumStrong">
											{validation.experimentKey}
										</Text>
										&nbsp;
									</>
								)}
								<Text as="span" variant="medium">
									{validation.name}
								</Text>
							</>
						</ExperimentWrapper>
						<Description description={validation.description} shortDescription={validation.shortDescription} />
					</Stack>
				</TableDataCell>
				<TableDataCell justifyContent="flex-end">
					{validation.experimentKey ? (
						<Stack direction="horizontal" size="small" alignItems="center">
							<RouterButtonIcon
								to={`/experiments/edit/${validation.experimentKey}${lastRun ? '/executions/' + lastRun.id : '/design'}`}
								tooltip={`Go to experiment ${validation.experimentKey}`}
								variant="secondary"
							>
								<IconExperiment />
							</RouterButtonIcon>
							{lastRun && (
								<Container display={'flex'} flexDirection={'column'}>
									<Container maxWidth={150}>
										<StateBadge as="state" value={lastRun.state} />
									</Container>
									<RouterLink mr={'xSmall'} to={`/experiments/edit/${lastRun.experimentKey}/executions`}>
										<Text variant={'small'} sx={{ color: 'neutral600', fontVariantNumeric: 'tabular-nums' }}>
											{`${formatDateWithTime(lastRun.requested)} `}
										</Text>
									</RouterLink>
								</Container>
							)}
						</Stack>
					) : (
						<Tooltip
							content={
								isDisabled
									? 'Experiment creation using templates is not included in your current plan.'
									: !permissions.experiments.canCreate
										? 'You need to be a member of the Team to create new experiments.'
										: undefined
							}
						>
							<Button
								px="xSmall"
								loading={loading}
								variant="primarySmall"
								disabled={!permissions.experiments.canCreate || isDisabled}
								onClick={async (e) => {
									setLoading(true);
									const template = await Services.adviceApi.getAdviceValidationTemplate(
										type,
										targetReference,
										adviceDefinitionId,
										validation.id,
									);
									if (template) {
										setValidationTemplate(template);
									} else {
										try {
											await Services.adviceApi.createExperiment(
												type,
												targetReference,
												adviceDefinitionId,
												validation.id,
												team.id,
												environments.selectedEnvironment?.id || environments.defaultSelectedEnvironment?.id,
											);
											reload();
											e.stopPropagation();
										} catch (e) {
											const statusCode = e?.response?.status;
											if (statusCode === 403) {
												Snackbar.error('You are not allowed to create this experiment', {
													toastId: 'create-advice-experiment-error',
												});
											} else {
												Snackbar.error('Experiment could not be created', {
													toastId: 'create-advice-experiment-error',
												});
											}
										} finally {
											setLoading(false);
										}
									}
								}}
							>
								<IconExperiment mr="xSmall" />
								Create Experiment
							</Button>
						</Tooltip>
					)}
				</TableDataCell>
			</TableRow>
		</>
	);
}

function ValidationTextRow({
	adviceDefinitionId,
	targetReference,
	tableWidth,
	validation,
	type,
}: ValidationRowProps): ReactElement {
	return (
		<TableRow key={validation.id} hoverable={true}>
			<TableDataCell maxWidth={64}>
				<Toggle
					checked={validation.validated}
					onChange={async (e) => {
						await Services.adviceApi.updateAdviceExperimentState(type, targetReference, adviceDefinitionId, {
							...validation,
							validated: e.target.checked,
						});
					}}
				/>
			</TableDataCell>
			<TableDataCell maxWidth={tableWidth - 66} colSpan={2}>
				<Stack size="none" py="xSmall">
					<Text as="span" variant="medium" muted={validation.validated}>
						{validation.name}
					</Text>
					<Description description={validation.description} shortDescription={validation.shortDescription} />
				</Stack>
			</TableDataCell>
		</TableRow>
	);
}

interface ExperimentWrapperProps {
	children: ReactElement;
	experimentKey: string | undefined;
}

function ExperimentWrapper({ children, experimentKey }: ExperimentWrapperProps): ReactElement | null {
	if (experimentKey) {
		return (
			<RouterLink
				to={`/experiments/edit/${experimentKey}`}
				sx={{
					overflow: 'hidden',
					textOverflow: 'ellipsis',
					whiteSpace: 'nowrap',
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
				}}
			>
				{children}
			</RouterLink>
		);
	}
	return <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>{children}</div>;
}

function ReadButton({ children, onClick }: { children: string; onClick: () => void }): ReactElement {
	return (
		<Text
			as="span"
			variant="smallStrong"
			onClick={onClick}
			sx={{
				width: '120px',
				whiteSpace: 'nowrap',
				color: 'slate',
				cursor: 'pointer',
				'&:hover': {
					textDecoration: 'underline',
				},
			}}
		>
			{children}
		</Text>
	);
}

interface ExperimentFromAdviceTemplateFormProps {
	environmentId: string | null;
	template: TemplateVO;
	onSubmit: (v: UseTemplateFormData) => void;
	onClose: () => void;
}

function ExperimentFromAdviceTemplateForm({
	environmentId,
	template,
	onSubmit,
	onClose,
}: ExperimentFromAdviceTemplateFormProps): ReactElement | null {
	const userResult = usePromise(() => Services.users.getCurrentUser(), []);
	const actionsResult = usePromise(() => Services.actions.fetchActions(), []);
	const environmentResult = usePromise(
		() => Services.environments.fetchEnvironment(environmentId ?? ''),
		[environmentId],
	);

	if (!userResult.value || !environmentResult.value || !actionsResult.value) {
		return null;
	} else if (userResult.error || environmentResult.error || actionsResult.error) {
		Snackbar.error('Experiment could not be created', {
			toastId: 'create-advice-experiment-error',
		});
		onClose();
		return null;
	}

	const environments = [
		{
			id: environmentId ?? '',
			name: environmentResult.value.name,
			// these are not used
			_actions: [],
			teams: [],
			predicate: '',
			global: false,
		},
	];

	return (
		<ModalOverlay onClose={onClose}>
			<UseTemplateActionsChecker
				withExperimentHypothesisExtraction={false}
				withExperimentNameExtraction={false}
				actions={actionsResult.value}
				environments={environments}
				newExperimentTags={[]}
				template={template}
				isCreatedByAdvice
				placeholders={[]}
				onIsEmpty={onSubmit}
				onSubmit={onSubmit}
				onClose={onClose}
			>
				<ModalV2 slick withFooter width="90vw" maxWidth="1650px">
					<ModalHeaderV2 title="Create Experiment from Advice Template" onClose={onClose} />
					<ModalContentV2>
						<NavigationWithContent
							selectedEnvironment={environments[0]}
							environments={environments}
							submitLabel="Create Experiment 🚀"
						/>
					</ModalContentV2>
				</ModalV2>
			</UseTemplateActionsChecker>
		</ModalOverlay>
	);
}
