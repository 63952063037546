/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { smellsLikeTemplatePlaceholder } from 'components';
import { FormikErrors, useFormikContext } from 'formik';
import { findStep } from 'pages/experimentsV2/utils';
import { Services } from 'services/services';
import { useEffect, useState } from 'react';
import { useTeam } from 'services/useTeam';
import { debounce, set } from 'lodash';

import { isOccuranceActionPredicateVO, isOccuranceStepParameterVO } from './types';
import { UseTemplateFormData } from './UseTemplateFormLoadingHandler';
import { createExperimentRequestFromTemplate } from './utils';
import { getStepId } from '../components/Occurances';

/**
 * Why a custom validation handler and not formiks validation handler?
 * Because formik has 2 issue with validation:
 * - it cannot debound the validation
 * - validation is always called before submitting the form. Submit is NOT called if the validation fails.
 * which is horrible because one must be able to save an invalid template form.
 */
export default function ValidationHandler(): null {
	const team = useTeam();
	const { values, setErrors } = useFormikContext<UseTemplateFormData>();

	const [debouncedValidate] = useState(() =>
		debounce(
			async (v: UseTemplateFormData) => {
				const errors = await validate(v, team.id);

				const ignoredFieldPaths = Array.from(getIgnoredFieldPaths(v));
				ignoredFieldPaths.forEach((fieldPath) => {
					set(errors, fieldPath, undefined);
				});

				setErrors(errors);
			},
			500,
			{ leading: true },
		),
	);

	useEffect(() => {
		debouncedValidate(values);
	}, [values, debouncedValidate]);

	return null;
}

async function validate(values: UseTemplateFormData, teamId: string): Promise<FormikErrors<UseTemplateFormData>> {
	const errors = {};
	try {
		const violations = await Services.experiments.validateExperiment(
			createExperimentRequestFromTemplate({ formData: values, teamId }),
		);
		violations.forEach(({ field, message }) => {
			if (message === 'There are no targets matching your query.') {
				set(errors, field, { message, level: 'info' });
			} else {
				set(errors, field, { message, level: 'error' });
			}
		});
	} catch {
		console.error('Could not validate template');
	}
	return errors;
}

function getIgnoredFieldPaths(values: UseTemplateFormData): Set<string> {
	const ignoredFieldPaths = new Set<string>();

	values.placeholdersMap.forEach((occurances, placeholderKey) => {
		const placeholderValue = values.placeholderValuesMap.get(placeholderKey);
		if (placeholderValue && !smellsLikeTemplatePlaceholder(placeholderValue)) {
			// The step has a placeholder value, so we can show errors for it, but only if the value is not a placeholder
			return;
		}

		occurances.forEach((occurance) => {
			// No step -> no field to ignore
			const stepId = getStepId(occurance);
			if (!stepId) {
				return;
			}

			// Should never happen, but we need to handle the optional path
			const [, stepPath] = findStep(values.__originalLanes, stepId);
			if (!stepPath) {
				return;
			}

			if (isOccuranceStepParameterVO(occurance)) {
				ignoredFieldPaths.add(`${stepPath}.parameters[${occurance.field.name}]`);
			} else if (isOccuranceActionPredicateVO(occurance)) {
				ignoredFieldPaths.add(`${stepPath}.blastRadius.predicate`);
				if (!occurance.usedAsFullQueryReplacement) {
					ignoredFieldPaths.add(`${stepPath}.blastRadius.predicateparts`);
				}
			}
		});
	});

	return ignoredFieldPaths;
}
