/*
 * Copyright 2023 steadybit GmbH. All rights reserved.
 */

export function isBlank(s: string | undefined | null): boolean {
	return s == null || s.trim().length === 0;
}

const collator = new Intl.Collator('en-US');
export function localeCompare(a: string, b: string): number {
	return collator.compare(a, b);
}

const ignoreCaseCollator = new Intl.Collator('en-US', { sensitivity: 'base' });
export function localeCompareIgnoreCase(a: string | undefined, b: string | undefined): number {
	if (a === undefined || b === undefined) {
		return 0;
	}
	return ignoreCaseCollator.compare(a, b);
}

export function toTitleCase(s: string): string {
	return s.replace(/( |^|-)+([^ ])/g, (_, space, char) => space + char.toUpperCase());
}

export function includes(s: string, search: string): boolean {
	return s.toLowerCase().includes(search.toLowerCase());
}

export function createStableId(strings: string[] = []): string {
	return strings.slice().sort(localeCompareIgnoreCase).join(',');
}
