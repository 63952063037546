/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Button, Colors, Dropdown, presets } from '@steadybit/ui-components-lib';
import { Container, Divider, Stack, Text } from 'components';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { ReactElement, useEffect, useState } from 'react';
import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { theme } from 'styles.v2/theme';
import { useField } from 'formik';

interface ExperimentTagsProps {
	initiallyOpen?: boolean;
	hasError?: boolean;
	disabled?: boolean;
	showTags?: boolean;
	maxWidth?: string;
	minWidth?: string;
	maxTags?: number;
}

/*
  This is pretty similar pages/templates/TemplateEditor/TemplateDescriptionContent.tsx
  but there are many small differences so we keep with 2 code bases
*/
export default function ExperimentTags({
	maxWidth = '300px',
	initiallyOpen,
	hasError,
	disabled,
	showTags,
	minWidth,
	maxTags,
}: ExperimentTagsProps): ReactElement {
	const [{ value: teamId }] = useField<string>('teamId');
	const [{ value: tags }] = useField<string[]>('tags');
	const editorInformationResult = usePromise(() => Services.experiments.getEditorInformation(teamId), [teamId]);
	const allTags = editorInformationResult.value?.allTags ?? [];
	const label = maxTags === 1 || tags.length === 1 ? 'Tag' : 'Tags';
	const labelDetails = tags.length > 0 ? (tags.length === 1 ? tags[0] : `(${tags.length})`) : '';
	const [, , { setTouched }] = useField<string[]>('tags');

	const content =
		showTags && tags.length > 0 ? (
			<span style={{ width: '100%' }}>
				<presets.pill.Tags appearance="experiment" tags={tags} small />
			</span>
		) : (
			`${label} ${labelDetails}`
		);

	return (
		<Dropdown
			placement="bottom-start"
			initiallyOpen={initiallyOpen}
			renderDropdownContent={({ close }) => (
				<presets.dropdown.DropdownContentFrame>
					<AddTagsWorkspace allTags={allTags} maxTags={maxTags} close={close} />
				</presets.dropdown.DropdownContentFrame>
			)}
			onClose={() => setTouched(true)}
		>
			{({ setRefElement, isOpen, setOpen }) => {
				return (
					<Button
						ref={setRefElement}
						withRightIcon={isOpen ? 'arrow-drop-up' : 'arrow-drop-down'}
						disabled={disabled}
						withLeftIcon="tag"
						type="secondary"
						size="small"
						style={{
							maxWidth,
							minWidth,
							outline: isOpen ? '2px solid ' + Colors.slate : hasError ? '2px solid ' + Colors.coral : undefined,
						}}
						onClick={() => setOpen(!isOpen)}
					>
						{content}
					</Button>
				);
			}}
		</Dropdown>
	);
}

interface AddTagsWorkspaceProps {
	allTags: string[];
	maxTags?: number;
	close: () => void;
}

function AddTagsWorkspace({ allTags, maxTags, close }: AddTagsWorkspaceProps): ReactElement {
	const [query, setQuery] = useState<string>('');

	const [{ value: tags }, , { setValue, setTouched }] = useField<string[]>('tags');
	const queryIsTooLong = query.length > 35;
	const queryMatchesExisitngTag =
		!queryIsTooLong && [...allTags, ...tags].some((tag) => tag.toLowerCase() === query.toLowerCase());

	const selectableTags = allTags.filter(
		(tag) => tag.toLowerCase().includes(query.toLowerCase()) && !tags.includes(tag),
	);

	const helpText = maxTags ? (maxTags === 1 ? 'Assign one Tag' : `Assign ${maxTags} Tags`) : 'Assign Tags';
	return (
		<presets.dropdown.DropdownContentFrame maxWidth="500px">
			<Stack m="xSmall">
				<Text variant="largeStrong">{helpText}</Text>

				<Stack size="xxSmall">
					<Container
						sx={{
							position: 'relative',
							display: 'flex',
							alignItems: 'center',
							flexWrap: 'wrap',
							gap: '6px',

							width: '100%',
							minWidth: '450px',
							height: 'fit-content',
							p: '4px',

							color: 'neutral800',
							outline: '2px solid ' + theme.colors.slate,
							borderRadius: '2px',
						}}
					>
						<presets.pill.Tags
							appearance="experiment"
							tags={tags}
							onDelete={(tag) => {
								setValue(tags.filter((t) => t !== tag));
								setTouched(true);
							}}
						/>
						<Container
							as="input"
							value={query}
							placeholder={
								maxTags && maxTags === tags.length
									? undefined
									: selectableTags.length === 0
										? 'Type to create new tag'
										: 'Type to search'
							}
							onChange={(e) => setQuery(e.target.value)}
							disabled={maxTags ? maxTags === tags.length : false}
							autoFocus
							sx={{
								flexGrow: 1,
								height: '32px',
								...textEllipsis,
								outline: 'none',
								border: 'none',
								minWidth: '100px',
							}}
						/>
					</Container>

					{queryIsTooLong && (
						<Text variant="smallStrong" color="coral">
							Tag name must be shorter than 35 characters
						</Text>
					)}
					{maxTags && maxTags === tags.length && (
						<Text variant="small" color="neutral600">
							If you want to select another tag, remove{' '}
							{maxTags === 1 ? 'the selected tag' : 'one of the selected tags'} first
						</Text>
					)}
				</Stack>

				{selectableTags.length > 0 && (!maxTags || tags.length < maxTags) && (
					<Stack size={'xSmall'}>
						<Text variant="small" color="neutral600">
							Select an existing tag or add a new one
						</Text>
						<Container
							sx={{
								display: 'flex',
								flexWrap: 'wrap',
								gap: '8px',
								mt: 'xSmall',
							}}
						>
							<presets.pill.Tags
								appearance="experiment"
								tags={selectableTags}
								onClick={(tag) => {
									const newTags = [...tags, tag];
									setValue(newTags);
									setTouched(true);
									setQuery('');
									if (newTags.length === maxTags) {
										close();
									}
								}}
							/>
						</Container>
					</Stack>
				)}

				{!queryIsTooLong && query.length > 0 && !queryMatchesExisitngTag && (
					<>
						{selectableTags.length > 0 && <Divider />}
						<Stack direction="horizontal" alignItems="center">
							<Text variant="small" color="neutral600">
								Create
							</Text>
							<NewTag
								onApply={() => {
									setValue([...tags, query]);
									setTouched(true);
									setQuery('');
								}}
							>
								{query}
							</NewTag>
						</Stack>
					</>
				)}
			</Stack>
		</presets.dropdown.DropdownContentFrame>
	);
}

function NewTag({ children, onApply }: { children: string; onApply: () => void }): ReactElement {
	const keyDownListener = (event: KeyboardEvent): void => {
		if (event.key === 'Enter') {
			event.preventDefault();
			event.stopPropagation();
			onApply();
		}
	};

	useEffect(() => {
		window.addEventListener('keydown', keyDownListener, true);
		return () => window.removeEventListener('keydown', keyDownListener, true);
	}, [keyDownListener]);

	return (
		<presets.pill.Tag appearance="experiment" onClick={onApply}>
			{children}
		</presets.pill.Tag>
	);
}
