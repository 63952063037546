/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Button, Code, Heading, Link, Message, Stack, Text } from 'components';
import { DataStreamResult } from 'utils/hooks/stream/result';
import { ExperimentExecutionVO } from 'ui-api';
import { useTenant } from 'tenancy/useTenant';
import { ReactElement } from 'react';

import useTrackingExperimentExecutionViewed from './hooks/useTrackingExperimentExecutionViewed';

export default function ExperimentExecutionTelemetry({
	value,
}: DataStreamResult<ExperimentExecutionVO | undefined>): ReactElement | null {
	const tenant = useTenant();
	if (!value) {
		return null;
	}
	useTrackingExperimentExecutionViewed(value, 'tracing');
	return (
		<Stack mx="xxxLarge" my="xLarge" size="xLarge" maxWidth={1000}>
			<Stack size="large">
				<Stack size="medium">
					<Heading variant="medium">Analyze the Communication Flow during Experiments</Heading>
					<Text variant="medium">
						Analyze and understand the exact behavior of the communication between the Steadybit platform and agents.
						For every experiment run, we automatically collect distributed tracing spans using{' '}
						<Link href="https://opentelemetry.io/" external>
							OpenTelemetry
						</Link>{' '}
						across the Steadybit platform and agents. This helps you to verify, e.g., what Steadybit has done or to
						debug your own developed extension. You can learn more about this through our{' '}
						<Link href="https://docs.steadybit.com/use-steadybit/experiments/opentelemetry-integration" external>
							documentation
						</Link>
						.
					</Text>
				</Stack>

				{!value.hasSpans ? (
					<Message variant={'warning'} title={'No Tracing Spans could be found'} flex={'50%'}>
						The default retention for tracing spans is 28 days.
					</Message>
				) : (
					<>
						<Stack size="small">
							<Heading variant="small">1. Download Tracing Spans</Heading>
							<Text variant="medium">Download the recorded distributed tracing spans below.</Text>

							<a
								href={`/ui/experiments/executions/${value.id}/spans?tenantKey=${encodeURIComponent(tenant.key)}`}
								target="_blank"
								rel="noreferrer"
							>
								<Button variant="secondary" mr="auto">
									Download Spans as OTLP JSON
								</Button>
							</a>
						</Stack>

						<Stack size="small">
							<Heading variant="small">2. Inspecting this Data</Heading>
							<Text variant="medium">
								You can import this data into all observability tools with an OTLP endpoint. The following bash script
								inserts every JSON file using cURL into a system accepting OTLP JSON, e.g.,{' '}
								<Link href="https://www.jaegertracing.io/" external>
									Jaeger
								</Link>
								.
							</Text>

							<Code lang="bash" withCopyToClipboard>
								{`
unzip experiment_execution_spans_${value.id}.zip
ls | grep spans_${value.id}_ | xargs -I {} curl -H 'Content-Type: application/json' http://localhost:4318/v1/traces --data-binary @{}
rm spans_${value.id}_*.otlp.json`.trim()}
							</Code>

							<Text variant="medium">
								We recommend spinning up a local Jaeger instance if you do not have such a system. Follow{' '}
								<Link href="https://www.jaegertracing.io/docs/1.43/getting-started/#all-in-one" external>
									Jaeger&apos;s getting started guide
								</Link>{' '}
								to quickly get a local instance through which you can inspect the data.
							</Text>
						</Stack>
					</>
				)}
			</Stack>
		</Stack>
	);
}
