/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { Button, Colors, Flex, metrics, presets, SingleChoiceListItem, Text } from '@steadybit/ui-components-lib';
import { IconReport, IconTrendDown, IconTrendUp } from 'components/icons';
import { DashboardWidget } from 'components/DashboardWidget';
import { useAsyncState } from 'utils/hooks/useAsyncState';
import { forIn, groupBy, orderBy, sum } from 'lodash';
import { daysAgo, formatTime } from 'utils/dateFns';
import { withBaseHref } from 'utils/getBaseHref';
import { ReactElement, useState } from 'react';
import { ExecutionStatsGrouped } from 'ui-api';
import { useTenant } from 'tenancy/useTenant';
import { Services } from 'services/services';
import { useTeam } from 'services/useTeam';
import { Link } from 'components';

import { ampli } from '../../../ampli';

const mapTimeRangeOptionsToAmplitude = (id: string): '7_days' | '30_days' | 'all_time' => {
	if (id === '7') {
		return '7_days';
	} else if (id === '30') {
		return '30_days';
	} else {
		return 'all_time';
	}
};

export function TeamActivitiesWidget(props: { flex: string }): ReactElement {
	const [timeRange, setTimeRange] = useState('7');
	const timeRangeOptions: SingleChoiceListItem[] = [
		{
			id: '7',
			label: 'last 7 Days',
		},
		{
			id: '30',
			label: 'last 30 Days',
		},
		{
			id: 'all',
			label: 'all time',
		},
	];

	const selectedTimeRange = timeRangeOptions.find((option) => option.id === timeRange);

	return (
		<DashboardWidget
			{...props}
			title="Activities of your Team"
			icon={IconReport}
			actionsLeft={
				<presets.dropdown.SingleChoiceButton
					placement="bottom-start"
					selectedId={timeRange}
					items={timeRangeOptions}
					size="small"
					onSelect={(id) => {
						setTimeRange(id);
						ampli.dashboardTeamActivityFiltered({ team_activity_time_range: mapTimeRangeOptionsToAmplitude(id) });
					}}
					maxContentHeight="250px"
				>
					{selectedTimeRange?.label || ''}
				</presets.dropdown.SingleChoiceButton>
			}
			actionsRight={<ReportLink timeRange={timeRange} />}
		>
			<LatestStats lastNoOfDays={timeRange === 'all' ? null : parseInt(timeRange)} />
		</DashboardWidget>
	);
}

function ReportLink({ timeRange }: { timeRange: string }): ReactElement {
	const lastNoOfDays = timeRange === 'all' ? null : parseInt(timeRange);
	const tenant = useTenant();
	const team = useTeam();
	const params = new URLSearchParams({ tenantKey: tenant.key, teamIds: team.id });
	if (lastNoOfDays) {
		params.set('createdFrom', daysAgo(lastNoOfDays).toISOString());
	}
	return (
		<Link
			href={withBaseHref(`/ui/reports/experiments/runs?${params.toString()}`)}
			onClick={() => {
				ampli.dashboardTeamActivityExported({ team_activity_time_range: mapTimeRangeOptionsToAmplitude(timeRange) });
			}}
			variant={'primary'}
			display={'inline'}
			style={{
				textDecoration: 'none',
			}}
		>
			<Button type="secondary" size="small" withLeftIcon="save-file" onClick={() => {}}>
				Export csv
			</Button>
		</Link>
	);
}

const groupStats = (stats: ExecutionStatsGrouped[]): ExecutionStatsGrouped[] => {
	const groupedByDate = groupBy(stats, 'date');
	const result: ExecutionStatsGrouped[] = [];
	forIn(groupedByDate, (value, key) => {
		result.push({
			date: key,
			count: sum(value.map((v) => v.count)),
		});
	});
	if (result.length === 1) {
		result.push(result[0]);
	}
	return result;
};

function LatestStats({ lastNoOfDays }: { lastNoOfDays: number | null }): ReactElement {
	const team = useTeam();
	const [stats] = useAsyncState(async () => {
		const [currentExperiments, beforeExperiments] = await Promise.all([
			Services.experiments.fetchRunStats(team.id, lastNoOfDays !== null ? daysAgo(lastNoOfDays) : undefined),
			lastNoOfDays !== null
				? Services.experiments.fetchRunStats(team.id, daysAgo(2 * lastNoOfDays), daysAgo(lastNoOfDays))
				: undefined,
		]);
		const current = {
			executed: currentExperiments?.executed || 0,
			completed: currentExperiments?.completed || 0,
			failed: currentExperiments?.failed || 0,
			errored: currentExperiments?.errored || 0,
			executedStats: orderBy(groupStats(currentExperiments?.executedStats), ['date'], ['asc']),
			completedStats: orderBy(groupStats(currentExperiments?.completedStats), ['date'], ['asc']),
			failedStats: orderBy(groupStats(currentExperiments?.failedStats), ['date'], ['asc']),
			erroredStats: orderBy(groupStats(currentExperiments?.erroredStats), ['date'], ['asc']),
		};
		const before = {
			executed: beforeExperiments?.executed || 0,
			completed: beforeExperiments?.completed || 0,
			failed: beforeExperiments?.failed || 0,
			errored: beforeExperiments?.errored || 0,
		};
		return { current, before };
	}, [team.id, lastNoOfDays]);

	const hasNoOfDays: boolean =
		lastNoOfDays && lastNoOfDays > 0 && stats.value?.before?.executed && stats.value?.before?.executed > 0
			? true
			: false;

	const trendDisplayVersion = stats.value?.current
		? getTrendDisplayVersion(
				stats.value?.current?.executed,
				stats.value?.current?.completed,
				stats.value?.current?.failed,
				stats.value?.current?.errored,
			)
		: 'large';
	return (
		<>
			<Flex direction="horizontal" justify="spread" wrap>
				<TrendItem
					name={'Executed'}
					current={stats.value?.current?.executed}
					before={stats.value?.before?.executed}
					stats={stats.value?.current?.executedStats}
					displayVersion={trendDisplayVersion}
				/>
				<TrendItem
					name={'Completed'}
					current={stats.value?.current?.completed}
					before={stats.value?.before?.completed}
					stats={stats.value?.current?.completedStats}
					displayVersion={trendDisplayVersion}
				/>
				<TrendItem
					name={'Failed'}
					current={stats.value?.current?.failed}
					before={stats.value?.before?.failed}
					invertColor={true}
					stats={stats.value?.current?.failedStats}
					displayVersion={trendDisplayVersion}
				/>
				<TrendItem
					name={'Errored'}
					current={stats.value?.current?.errored}
					before={stats.value?.before?.errored}
					invertColor={true}
					stats={stats.value?.current?.erroredStats}
					displayVersion={trendDisplayVersion}
				/>
			</Flex>
			{hasNoOfDays && (
				<Text type="small" style={{ color: Colors.neutral500 }}>
					Percentage values reflect development compared to previous {lastNoOfDays} days.
				</Text>
			)}
		</>
	);
}

const getTrendDisplayVersion = (...values: number[]): 'small' | 'medium' | 'large' => {
	if (Math.max(...values) >= 1000) {
		return 'small';
	} else if (Math.max(...values) >= 100) {
		return 'medium';
	}
	return 'large';
};

interface TrendItemProps {
	displayVersion: 'small' | 'medium' | 'large';
	stats?: ExecutionStatsGrouped[];
	invertColor?: boolean;
	current?: number;
	before?: number;
	name: string;
}
function TrendItem({
	invertColor = false,
	displayVersion,
	current,
	before,
	stats,
	name,
}: TrendItemProps): ReactElement {
	return (
		<Flex spacing="none" justify="center" align="start">
			<Text type="smallMedium">{name}</Text>
			<Flex direction="horizontal" spacing="xxSmall">
				<Text type="xxLargeStrong">{current}</Text>

				{stats && stats.length > 0 && (
					<div style={{ width: displayVersion === 'small' ? 50 : displayVersion === 'medium' ? 80 : 100 }}>
						<metrics.SparkChart
							data={stats.map((d) => ({
								ts: new Date(d.date).getTime(),
								value: d.count,
							}))}
							height={35}
							formatTime={(v) => formatTime(new Date(v))}
						/>
					</div>
				)}
			</Flex>

			<TrendIcon current={current} before={before} invertColor={invertColor} />
		</Flex>
	);
}

interface TrendIconProps {
	invertColor: boolean;
	current?: number;
	before?: number;
}
function TrendIcon({ current, before, invertColor }: TrendIconProps): ReactElement {
	if (typeof current === 'undefined' || typeof before === 'undefined' || before === 0) {
		return <Text type="xSmall">&nbsp;</Text>;
	}
	const resultNum = Math.abs(Number(100 - (current / before) * 100));
	const result = resultNum.toFixed(1);
	if (resultNum !== 0) {
		if (invertColor ? current < before : current > before) {
			return (
				<Text type="smallStrong" style={{ color: Colors.cyan700 }}>
					<IconTrendUp height={16} width={16} />
					{result}%
				</Text>
			);
		}

		if (invertColor ? current > before : current < before) {
			return (
				<Text type="smallStrong" style={{ color: Colors.coral }}>
					<IconTrendDown height={16} width={16} />
					{result}%
				</Text>
			);
		}
	}
	return <Text type="xSmall">{result}%</Text>;
}
