/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { ServerExecutionMetricEvent, useExecutionMetrics } from 'services/executionMetricsStreams';
import { DataStreamResult, loadingResult } from 'utils/hooks/stream/result';
import { localeCompareIgnoreCase } from 'utils/string';
import { StateOverTimeWidgetVO } from 'ui-api';
import { ReactElement } from 'react';

import StateOverTimeCardPresenter, { DataPoint, StateOverTimeMetric } from './StateOverTimeCardPresenter';
import { WidgetProps } from '../types';

export default function StateOverTimeCard({
	experimentExecution,
	position,
	duration,
	widget,
	start,
}: WidgetProps): ReactElement {
	const ended = !!experimentExecution.ended;
	let metrics = useStateOverTimeMetrics(widget as StateOverTimeWidgetVO);

	// Simulate on-going loading when there are no data points and the experiment has not yet ended.
	if (metrics.loading || (metrics.value?.length === 0 && !ended)) {
		metrics = loadingResult;
	}

	return (
		<StateOverTimeCardPresenter
			widget={widget as StateOverTimeWidgetVO}
			position={position}
			duration={duration}
			metrics={metrics}
			start={start}
			ended={ended}
		/>
	);
}

function useStateOverTimeMetrics(widget: StateOverTimeWidgetVO): DataStreamResult<StateOverTimeMetric[]> {
	return useExecutionMetrics({
		filterMetric: (_metric: ServerExecutionMetricEvent) => !!_metric.labels[widget.identity.from],
		transformResponse: (_metrics) => {
			const groupedMetrics = new Map<string, StateOverTimeMetric>();
			_metrics.forEach((metric) => {
				const id = metric.labels[widget.identity.from];
				const label = metric.labels[widget.label.from];
				const existing = groupedMetrics.get(id);
				const dataPoint = {
					value: metric.value,
					from: metric.ts,
					to: undefined, // will be calculated later
					tooltip: metric.labels[widget.tooltip.from],
					state: metric.labels[widget.state.from],
					url: metric.labels[widget.url.from],
				};
				if (existing) {
					existing.dataPoints.push(dataPoint);
				} else {
					groupedMetrics.set(id, {
						id,
						label,
						dataPoints: [dataPoint],
					});
				}
			});
			return Array.from(groupedMetrics.values())
				.map((group) => {
					const sortedByTime = group.dataPoints.slice().sort((a, b) => a.from - b.from);
					sortedByTime.forEach((dataPoint, i) => {
						dataPoint.to = sortedByTime[i + 1]?.from || dataPoint.to;
					});
					group.dataPoints = mergeFollowingDataPointsByState(sortedByTime);
					return group;
				})
				.sort((a, b) => localeCompareIgnoreCase(a.label, b.label));
		},
	});
}

// exported for testing
export function mergeFollowingDataPointsByState(dataPoints: DataPoint[]): DataPoint[] {
	const mergedDataPoints: DataPoint[] = [];
	let current: DataPoint | undefined;
	for (const dataPoint of dataPoints) {
		if (!current) {
			current = dataPoint;
		} else if (current.state === dataPoint.state && current.to === dataPoint.from) {
			current.to = dataPoint.to;
		} else {
			mergedDataPoints.push(current);
			current = dataPoint;
		}
	}
	if (current) {
		mergedDataPoints.push(current);
	}
	return mergedDataPoints;
}
