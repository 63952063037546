/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { createContext, DependencyList, useContext, useEffect, useState } from 'react';
import { useObservable as useObservableInternal } from 'utils/hooks/useObservable';
import { DataStreamResult, loadingResult } from 'utils/hooks/stream/result';
import { debounceTime, map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { withBaseHref } from 'utils/getBaseHref';

interface Label {
	[key: string]: string;
	name: string;
}
export interface ServerExecutionMetricEvent {
	value: number;
	labels: Label;
	ts: number;
}
export type ServerExecutionEvent = ServerExecutionMetricEvent;

class ExecutionMetricStreamStore {
	private eventSource?: EventSource;
	private subject = new BehaviorSubject<ServerExecutionEvent[]>([]);
	private timeoutHandler: NodeJS.Timeout | undefined;
	private metrics: ServerExecutionEvent[] = [];

	async start(tenantKey: string, executionId: number): Promise<void> {
		this.stop();

		this.eventSource = new EventSource(
			withBaseHref(`/ui/executions/${executionId}/metrics/stream?tenantKey=${tenantKey}`),
		) as EventSource;
		this.eventSource.onopen = () => {};
		this.eventSource.onmessage = (message) => {
			const data = JSON.parse(message.data);
			this.metrics.push({
				value: data.value,
				labels: { ...data.labels, name: data.labels.__name__ },
				ts: data.ts,
			});
			this.subject.next(this.metrics);
		};
		this.eventSource.onerror = () => {
			this.stop();

			this.timeoutHandler = setTimeout(() => {
				this.start(tenantKey, executionId);
				this.timeoutHandler = undefined;
			}, 10_000);
		};
	}

	get events$(): Observable<ServerExecutionEvent[]> {
		return this.subject;
	}

	stop(): void {
		this.metrics = [];

		if (this.timeoutHandler) {
			clearTimeout(this.timeoutHandler);
			this.timeoutHandler = undefined;
		}
		if (this.eventSource) {
			this.eventSource.close();
			this.eventSource = undefined;
		}
	}
}

export function useExecutionMetricStore(tenantKey: string, executionId?: number): ExecutionMetricStreamStore | null {
	const [store, setStore] = useState<ExecutionMetricStreamStore | null>(null);
	useEffect(() => {
		const metricStreamStore = new ExecutionMetricStreamStore();
		setStore(metricStreamStore);

		if (executionId) {
			metricStreamStore.start(tenantKey, executionId);
		}

		return () => {
			metricStreamStore.stop();
		};
	}, [executionId]);

	return store;
}

type ExperimentExecutionMetricStoreType = {
	metricStore: ExecutionMetricStreamStore | null;
};
export const ExperimentExecutionMetricStoreContext = createContext<ExperimentExecutionMetricStoreType>({
	metricStore: null,
});

type MetricsTransformer<T> = (metrics: ServerExecutionEvent[]) => T[];
type MetricFilterFunction = (metric: ServerExecutionEvent) => boolean;

export function useExecutionMetrics<T>({
	dependencies = [],
	filterMetric = () => true,
	transformResponse = (m) => m as unknown as T[],
}: {
	dependencies?: DependencyList;
	filterMetric?: MetricFilterFunction;
	transformResponse?: MetricsTransformer<T>;
}): DataStreamResult<T[]> {
	const metricStore = useContext(ExperimentExecutionMetricStoreContext).metricStore;
	if (!metricStore) {
		return loadingResult;
	}

	return useObservableInternal<T[]>(
		() =>
			metricStore.events$
				.pipe(debounceTime(1_000))
				.pipe(map((metrics) => metrics.filter(filterMetric)))
				.pipe(map(transformResponse)),
		dependencies,
	);
}
