/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	EnvironmentSummaryVO,
	ExperimentLaneVO,
	OccuranceVO,
	TemplatePlaceholderVO,
	TemplateVO,
	VariableVO,
} from 'ui-api';
import { EditorSettingsContext } from 'pages/experimentsV2/useEditorSettings';
import { ModalContentV2, ModalHeaderV2, ModalV2 } from 'components';
import { useEnvironments } from 'utils/hooks/useEnvironments';
import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { ReactElement } from 'react';
import { useField } from 'formik';

import UseTemplateActionsChecker from './UseTemplateActionsChecker';
import NavigationWithContent from './pages/NavigationWithContent';
import LoadingTemplateModal from './LoadingTemplateModal';
import ExperimentNameField from './ExperimentNameField';

export type UseTemplateFormData = TemplateVO & {
	additionalPlaceholders: TemplatePlaceholderVO[];
	placeholdersMap: Map<string, OccuranceVO[]>;
	placeholderValuesMap: Map<string, string>;
	variablesMap: Map<string, OccuranceVO[]>;
	variableValuesMap: Map<string, string>;
	initialMetadataLoaded: boolean;
	newExperimentTags?: string[];
	variables: VariableVO[];
	environmentId: string;
	__originalLanes: ExperimentLaneVO[];
	__originalExperimentName: string;
	__originalHypothesis: string;
};

interface UseTemplateFormLoadingHandlerProps {
	newExperimentTags?: string[];
	template?: TemplateVO;
	templateId: string;
	onSubmit: (values: UseTemplateFormData) => void;
	onClose: () => void;
}

export default function UseTemplateFormLoadingHandler({
	newExperimentTags,
	templateId,
	template,
	onSubmit,
	onClose,
}: UseTemplateFormLoadingHandlerProps): ReactElement {
	const templateResult = usePromise(
		() => (template ? Promise.resolve(template) : Services.templatesApi.getTemplate(templateId)),
		[templateId],
	);

	const actions = usePromise(() => Services.actions.fetchActions(), []);

	const { environmentsOfCurrentTeam: environments, environmentsIsLoading } = useEnvironments();
	if (environmentsIsLoading || environments.length === 0 || !templateResult.value || !actions.value) {
		return <LoadingTemplateModal onClose={onClose} />;
	}

	return (
		<UseTemplateActionsChecker
			newExperimentTags={newExperimentTags}
			template={templateResult.value}
			environments={environments}
			isCreatedByAdvice={false}
			actions={actions.value}
			placeholders={[]}
			onIsEmpty={onSubmit}
			onSubmit={onSubmit}
			onClose={onClose}
		>
			<ModalContentWithFormContext environments={environments} onClose={onClose} />
		</UseTemplateActionsChecker>
	);
}

interface ModalContentWithFormContextProps {
	environments: EnvironmentSummaryVO[];
	onClose: () => void;
}

function ModalContentWithFormContext({ environments, onClose }: ModalContentWithFormContextProps): ReactElement {
	const [{ value: environmentId }] = useField<string>('environmentId');
	const selectedEnvironment =
		environments.find((env) => env.id === environmentId) || environments.find((env) => env.global);

	return (
		<EditorSettingsContext.Provider value={{ mode: 'templateUsage' }}>
			<ModalV2 slick withFooter width="90vw" maxWidth="1650px">
				<ModalHeaderV2
					title="Create New Experiment From Template"
					subTitle={<ExperimentNameField />}
					onClose={onClose}
				/>
				<ModalContentV2>
					<NavigationWithContent
						selectedEnvironment={selectedEnvironment}
						environments={environments}
						submitLabel="Create Experiment 🚀"
					/>
				</ModalContentV2>
			</ModalV2>
		</EditorSettingsContext.Provider>
	);
}
