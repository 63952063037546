/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	CreateExperimentTemplateRequest,
	GetExperimentTemplatePreviewsPageResponse,
	ImportTemplatesFromHubRequest,
	ListResponse,
	SearchObjectsVO,
	TemplateDeletionMethodVO,
	TemplateEditorInformationVO,
	TemplateFromHubRequestVO,
	TemplateMetadataVO,
	TemplatePreviewsRequestVO,
	TemplateSummaryVO,
	TemplateVO,
	UpdateExperimentTemplateRequest,
	ViolationVO,
} from 'ui-api';
import { yml } from 'utils/mediaTypes';
import axios from 'axios';

export class TemplatesApi {
	async getTemplatePreviews(body: TemplatePreviewsRequestVO): Promise<GetExperimentTemplatePreviewsPageResponse> {
		return (await axios.post<GetExperimentTemplatePreviewsPageResponse>('/ui/experiments/templates/previews', body))
			.data;
	}

	async getTemplate(id: string): Promise<TemplateVO> {
		return (await axios.get<TemplateVO>('/ui/experiments/templates/' + id)).data;
	}

	async duplicateTemplate(id: string): Promise<TemplateVO> {
		return (await axios.get<TemplateVO>('/ui/experiments/templates/duplicate/' + id)).data;
	}

	async getSearchObjects(): Promise<SearchObjectsVO> {
		return (await axios.get<SearchObjectsVO>('/ui/experiments/templates/search_objects')).data;
	}

	async createTemplate(template: CreateExperimentTemplateRequest): Promise<TemplateVO> {
		return (await axios.post<TemplateVO>('/ui/experiments/templates', template)).data;
	}

	async createTemplateFromFileContent(fileContent: string, contentType?: string): Promise<TemplateVO> {
		if (!contentType) {
			contentType = yml;
		}
		return (
			await axios.post<TemplateVO>('/ui/experiments/templates/from_file', fileContent, {
				headers: {
					'Content-Type': contentType,
				},
			})
		).data;
	}

	async updateTemplate(
		id: string,
		template: UpdateExperimentTemplateRequest,
		forceInvalid: boolean = false,
	): Promise<TemplateVO> {
		return (
			await axios.post<TemplateVO>(
				`/ui/experiments/templates/${id}?forceInvalid=${forceInvalid ? 'true' : 'false'}`,
				template,
			)
		).data;
	}

	async getEditorInformation(): Promise<TemplateEditorInformationVO> {
		return (await axios.get<TemplateEditorInformationVO>('/ui/experiments/templates/editor_information')).data;
	}

	async getTemplateMetadata(template: TemplateVO, environmentId?: string): Promise<TemplateMetadataVO> {
		if (!environmentId) {
			return (await axios.post<TemplateMetadataVO>('/ui/experiments/templates/metadata', template)).data;
		}
		return (
			await axios.post<TemplateMetadataVO>(
				`/ui/experiments/templates/metadata?environmentId=${environmentId}`,
				template,
			)
		).data;
	}

	async deleteTemplate(id: string, deletionMethod: TemplateDeletionMethodVO): Promise<void> {
		return (await axios.delete<void>('/ui/experiments/templates/' + id, { params: { deletionMethod } })).data;
	}

	async validateTemplate(template: TemplateVO): Promise<ViolationVO[]> {
		return (await axios.post<ViolationVO[]>('/ui/experiments/templates/validate', template)).data;
	}

	async getTemplatesFromHub(body: TemplateFromHubRequestVO): Promise<ListResponse<TemplateSummaryVO>> {
		return (await axios.post<ListResponse<TemplateSummaryVO>>('/ui/experiments/templates/hub', body)).data;
	}

	async getSearchObjectsFromHub(hubId: string): Promise<SearchObjectsVO> {
		return (await axios.get<SearchObjectsVO>(`/ui/experiments/templates/hub/${hubId}/search_objects`)).data;
	}

	async getTemplatesAsApiFormat(templateId: string): Promise<unknown> {
		return (await axios.get<unknown>(`/ui/experiments/templates/${templateId}/api-format`)).data;
	}

	async importTemplatesFromHub(body: ImportTemplatesFromHubRequest, overwrite: boolean): Promise<void> {
		return (await axios.post<void>(`/ui/experiments/templates/imports?overwrite=${overwrite ? 'true' : 'false'}`, body))
			.data;
	}

	async getPermissions(teamId: string): Promise<string[]> {
		return (await axios.get(`/ui/experiments/templates/permissions/${teamId}`)).data;
	}
}
