/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { usePromise } from 'utils/hooks/usePromise';
import { Services } from 'services/services';
import { useFormikContext } from 'formik';
import { ReactElement } from 'react';
import { TemplateVO } from 'ui-api';

import { UseTemplateFormData } from './UseTemplateFormLoadingHandler';
import LoadingTemplateModal from './LoadingTemplateModal';

interface UseTemplateEmptyCheckerProps {
	isCreatedByAdvice: boolean;
	children: ReactElement;
	template: TemplateVO;
	onIsEmpty: () => void;
	onClose: () => void;
}

export default function UseTemplateEmptyChecker({
	isCreatedByAdvice,
	template,
	children,
	onIsEmpty,
	onClose,
}: UseTemplateEmptyCheckerProps): ReactElement {
	const { values } = useFormikContext<UseTemplateFormData>();

	const hasPlaceholderResult = usePromise<boolean>(async () => {
		if (!values.initialMetadataLoaded) {
			return false;
		}
		const metadata = await Services.templatesApi.getTemplateMetadata(
			isCreatedByAdvice
				? template
				: {
						...template,
						experimentName: '',
						hypothesis: '',
					},
		);
		const hasPlaceholdersOrVariables =
			Object.keys(metadata.variables).length > 0 || Object.keys(metadata.placeholders).length > 0;

		if (!hasPlaceholdersOrVariables) {
			onIsEmpty();
		}
		return hasPlaceholdersOrVariables;
	}, [template.id, values.initialMetadataLoaded]);

	if (hasPlaceholderResult.value === undefined || hasPlaceholderResult.value === false) {
		if (isCreatedByAdvice) {
			return <></>;
		}
		return <LoadingTemplateModal onClose={onClose} />;
	}

	return children;
}
