/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import {
	Container,
	RouterLink,
	RouterPagination,
	Stack,
	StateBadge,
	Table,
	TableBody,
	TableDataCell,
	TableErrorRow,
	TableHead,
	TableHeadCell,
	TableRow,
	TableSortLink,
	Text,
} from 'components';
import {
	EnvironmentSummaryVO,
	ExperimentExecutionSummaryVO,
	GetExperimentExecutionsPageResponse,
	TeamVO,
} from 'ui-api';
import AstroidScreen from 'components/List/AstroidScreen/AstroidScreen';
import ListHeaderTitle from 'components/List/presets/ListHeaderTitle';
import TeamIcon from 'pages/settings/teams/components/TeamIcon';
import TableLoadingRow from 'components/Table/TableLoadingRow';
import { useEnvironments } from 'utils/hooks/useEnvironments';
import { DataStreamResult } from 'utils/hooks/stream/result';
import ListHeader from 'components/List/presets/ListHeader';
import textEllipsis from 'utils/styleSnippets/textEllipsis';
import { presets } from '@steadybit/ui-components-lib';
import ViewWrapper from 'pages/components/ViewWrapper';
import Skeletons from 'components/Skeleton/Skeletons';
import { usePromise } from 'utils/hooks/usePromise';
import { formatDateWithTime } from 'utils/dateFns';
import { PageParams } from 'utils/hooks/usePage';
import { ReactElement, ReactNode } from 'react';
import { useUrlState } from 'url/useUrlState';
import { Services } from 'services/services';
import { IconRuns } from 'components/icons';
import { useUser } from 'services/usersApi';

import {
	directionParam,
	environmentIdsParam,
	pageParam,
	sortParam,
	statesParam,
	teamIdsParam,
	timeParam,
	UrlState,
} from './urlParams';
import FilterBar from './FilterBar';

interface ExperimentRunsProps {
	experimentExecutionsResult: DataStreamResult<GetExperimentExecutionsPageResponse>;
}

export default function ExperimentRuns({ experimentExecutionsResult }: ExperimentRunsProps): ReactElement {
	const experimentExecutions = experimentExecutionsResult.value;

	const environments = useEnvironments();

	const teamsResult = usePromise(() => Services.teams.fetchTeams(new PageParams(0, 1_000)), []);
	const teams = teamsResult.value?.content || [];

	const [{ environmentIds, teamIds, states, time, page }, getWithUrlState] = useUrlState<UrlState>([
		environmentIdsParam,
		directionParam,
		teamIdsParam,
		statesParam,
		timeParam,
		pageParam,
	]);
	const isAnyFilterDefined = environmentIds.length > 0 || teamIds.length > 0 || states.length > 0 || time !== undefined;

	const isEmptyResult =
		experimentExecutions && !experimentExecutionsResult.loading && experimentExecutions.content.length === 0;
	const isEmpty = !isAnyFilterDefined && isEmptyResult;

	return (
		<ViewWrapper>
			<Stack py="small" size="medium" minHeight="100%" mb="large">
				<ListHeader left={<ListHeaderTitle title="Runs" Icon={IconRuns} />} />

				{!isEmpty && (
					<>
						<FilterBar environments={environments.environments} />
						<ExperimentTable>
							{experimentExecutionsResult.error && <TableErrorRow error={experimentExecutionsResult.error.message} />}

							{experimentExecutionsResult.loading && !isEmpty ? (
								<>
									<TableLoadingRow numColumns={4} />
									<TableLoadingRow numColumns={4} />
									<TableLoadingRow numColumns={4} />
									<TableLoadingRow numColumns={4} />
									<TableLoadingRow numColumns={4} />
									<TableLoadingRow numColumns={4} />
								</>
							) : (
								experimentExecutions &&
								experimentExecutions.content.map((execution) => {
									return (
										<ExperimentRunRow
											environments={environments.environments}
											execution={execution}
											key={execution.id}
											teams={teams}
										/>
									);
								})
							)}
							{isEmptyResult && (
								<TableRow>
									<TableDataCell colSpan={6}>
										<Text muted data-cy="no-experiments-found">
											There are no experiment runs matching the filter criteria.
										</Text>
									</TableDataCell>
								</TableRow>
							)}
						</ExperimentTable>
						<RouterPagination
							activePage={page}
							totalPages={experimentExecutions?.totalPages}
							to={(i) => getWithUrlState({ page: i })}
						/>
					</>
				)}

				{isEmpty && (
					<Container
						data-cy="no-experiment-runs-found"
						sx={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							width: '100%',
							height: '100%',
						}}
					>
						<Container mt={-200} style={{ width: '100%', maxWidth: '650px' }}>
							<AstroidScreen
								title={
									<Text variant="xLargeStrong" color="slate">
										There are no experiment runs yet.
									</Text>
								}
								icon={<IconRuns variant="xxLarge" color="slate" />}
								description=""
							/>
						</Container>
					</Container>
				)}
			</Stack>
		</ViewWrapper>
	);
}

function ExperimentTable({ children }: { children: ReactNode }): ReactElement {
	const [{ direction }, getWithUrlState] = useUrlState<UrlState>([sortParam, directionParam]);

	return (
		<Table data-cy="experiment-runs-table" width="100%">
			<TableHead>
				<TableRow>
					<TableHeadCell>Run ID</TableHeadCell>
					<TableHeadCell>Environment</TableHeadCell>
					<TableHeadCell width="180px">
						<TableSortLink
							sort={direction === 'DESC' ? 'asc' : 'desc'}
							to={getWithUrlState({ sort: 'requested', direction: direction === 'DESC' ? 'ASC' : 'DESC' })}
						>
							Run Start / End
						</TableSortLink>
					</TableHeadCell>
					<TableHeadCell>State</TableHeadCell>
				</TableRow>
			</TableHead>
			<TableBody>{children}</TableBody>
		</Table>
	);
}

const ExperimentRunRow = ({
	environments,
	execution,
	teams,
}: {
	execution: ExperimentExecutionSummaryVO;
	environments: EnvironmentSummaryVO[];
	teams: TeamVO[];
}): ReactElement => {
	const environment = environments.find((env) => env.id === execution.environmentId);
	const team = teams.find((team) => team.key === execution.teamKey);
	const user = useUser();
	const userIsTeamMember = !!team?.members.find((member) => member.username === user.username);
	const experimentRunResult = Services.experiments.useExecution$(execution.id, true);
	const experimentRun = experimentRunResult.value || execution;

	return (
		<TableRow key={execution.id} hoverable={true}>
			<TableDataCell>
				<Stack direction="horizontal" size="xSmall" alignItems="center" py="xSmall">
					{team && (
						<TeamIcon
							tooltip={
								<>
									{team.name} ({team.key})
									{!userIsTeamMember && (
										<>
											<br />
											You aren&apos;t a member of this team, thus it is view only
										</>
									)}
								</>
							}
							userIsMember={userIsTeamMember}
							color={team.logoColor}
							logoId={team.logoId}
						/>
					)}
					<Stack size="xxxSmall">
						<Text variant="medium">#{execution.id}</Text>
						<RouterLink
							variant="secondary"
							to={`/experiments/edit/${execution.experimentKey}/executions/${execution.id}`}
						>
							<Stack direction="horizontal" size="xSmall">
								<Text variant="mediumStrong" as="span" sx={{ ...textEllipsis }}>
									{execution.experimentKey} {execution.name}
								</Text>
							</Stack>
						</RouterLink>
					</Stack>
				</Stack>
			</TableDataCell>
			<TableDataCell>
				<presets.pill.EnvironmentTag global={environment?.global} small>
					{environment?.name || ''}
				</presets.pill.EnvironmentTag>
			</TableDataCell>
			<TableDataCell>
				{experimentRun ? (
					<Stack size="xxSmall">
						<Text
							variant="small"
							sx={{ color: 'neutral600', fontVariantNumeric: 'tabular-nums', whiteSpace: 'nowrap' }}
						>
							{`${formatDateWithTime(experimentRun.requested)} ${experimentRun.ended ? '-' : ''}`}
						</Text>
						{experimentRun.ended && (
							<Text variant="small" sx={{ color: 'neutral600', fontVariantNumeric: 'tabular-nums' }}>
								{formatDateWithTime(experimentRun.ended)}
							</Text>
						)}
					</Stack>
				) : (
					<Stack size="xxSmall">
						<Skeletons widths={[100]} height={19} />
						<Skeletons widths={[100]} height={19} />
					</Stack>
				)}
			</TableDataCell>
			<TableDataCell>
				{experimentRun ? (
					<Stack size="none">
						<StateBadge as="state" value={experimentRun.state} />
						<Stack direction="horizontal" alignItems="center" size="xxSmall">
							<Text variant="small" as="span" color="neutral600">
								by
							</Text>
							<Text
								variant="smallStrong"
								sx={{ color: 'neutral600', overflow: 'hidden', textOverflow: 'ellipsis' }}
								noWrap
								data-private
							>
								{experimentRun.createdBy.name}
							</Text>
						</Stack>
					</Stack>
				) : (
					<Stack size="xxSmall">
						<Skeletons widths={[100]} height={19} />
						<Skeletons widths={[80]} height={19} />
					</Stack>
				)}
			</TableDataCell>
		</TableRow>
	);
};
