/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useCustomPrompt } from 'utils/hooks/useCustomPrompt';
import { userInput } from 'components/Dialog/UserInput';
import { EnvironmentVO, TeamSummaryVO } from 'ui-api';
import { usePromise } from 'utils/hooks/usePromise';
import { Grid } from '@steadybit/ui-components-lib';
import { Snackbar, userConfirm } from 'components';
import { Formik, useFormikContext } from 'formik';
import { ReactElement, useMemo } from 'react';
import { Services } from 'services/services';
import { useHistory } from 'url/hooks';
import { Helmet } from 'react-helmet';

import EditEnvironmentHeader, { LoadingEditEnvironmentHeader } from './EditEnvironmentHeader';
import { DataStreamResult } from '../../../../utils/hooks/stream/result';
import SidebarPredicateModule from './SidebarPredicateModule';
import DidNotSavePromptView from './DidNotSavePromptView';
import SidebarTeamsModule from './SidebarTeamsModule';
import HorizontalLine from './HorizontalLine';
import SidebarWrapper from './SidebarWrapper';
import { EnvironmentConfig } from './types';
import ResultView from './ResultView';

interface EditEnvironmentProps {
	environmentId: string;
}

export default function EditEnvironment({ environmentId }: EditEnvironmentProps): ReactElement {
	const history = useHistory();
	const teams = usePromise(() => Services.teams.getAllTeams(), []);
	const atLeastOneNonAdminTeam = useMemo(() => (teams.value && teams.value.length > 1) || false, [teams]);
	const isNewEnvironment = environmentId === '<new>';
	const environmentResult = usePromise(() => {
		if (isNewEnvironment) {
			return Promise.resolve(undefined);
		}
		return Services.environments.fetchEnvironment(environmentId);
	}, [environmentId]);

	if (environmentResult.loading) {
		return <LoadingEditEnvironmentHeader />;
	}

	return (
		<Formik<EnvironmentConfig>
			initialValues={
				environmentResult.value ?? {
					name: '',
					predicate: {
						predicates: [],
						operator: 'AND',
					},
					teamIds: [],
				}
			}
			onSubmit={async (values, helpers) => {
				helpers.setTouched({});
				const savedConfig = await save(isNewEnvironment, atLeastOneNonAdminTeam, values);
				if (savedConfig) {
					history.push('/settings/environments');
				}
			}}
			validateOnBlur={false}
			validateOnChange={false}
		>
			<>
				{environmentResult.value?.name && (
					<Helmet>
						<title>Settings / Environments / {environmentResult.value?.name}</title>
					</Helmet>
				)}
				<Content
					environmentId={environmentId}
					isNewEnvironment={isNewEnvironment}
					isGlobalEnvironment={environmentResult.value?.global ?? false}
					atLeastOneNonAdminTeamExists={atLeastOneNonAdminTeam}
					teams={teams}
				/>
			</>
		</Formik>
	);
}

interface ContentProps {
	environmentId: string;
	isNewEnvironment: boolean;
	isGlobalEnvironment: boolean;
	atLeastOneNonAdminTeamExists: boolean;
	teams: DataStreamResult<TeamSummaryVO[]>;
}

function Content({
	environmentId,
	isNewEnvironment,
	isGlobalEnvironment,
	teams,
	atLeastOneNonAdminTeamExists,
}: ContentProps): ReactElement {
	const history = useHistory();
	const formik = useFormikContext<EnvironmentConfig>();
	const formIsTouched = formik.touched.name || formik.touched.predicate || formik.touched.teamIds;

	const { showPrompt, resolve } = useCustomPrompt(!!formIsTouched, ({ pathname }) => {
		return !pathname.startsWith('/settings/environments/');
	});

	return (
		<>
			{showPrompt && (
				<DidNotSavePromptView
					resolve={resolve}
					onSave={async () => {
						return await save(isNewEnvironment, atLeastOneNonAdminTeamExists, formik.values);
					}}
				/>
			)}
			<EditEnvironmentHeader
				isNewEnvironment={isNewEnvironment}
				isGlobalEnvironment={isGlobalEnvironment}
				onDelete={async () => {
					try {
						formik.setTouched({});
						await Services.environments.deleteEnvironment(environmentId);
						history.push('/settings/environments');
					} catch (error) {
						console.error('error', error);
						Snackbar.error(error.title);
					}
				}}
			/>
			<Grid cols="512px 1fr" style={{ height: '100%', pb: 'large' }}>
				<SidebarWrapper>
					<SidebarTeamsModule addFakeAdminTeam={isNewEnvironment} teams={teams} />
					<HorizontalLine />
					<SidebarPredicateModule isGlobalEnvironment={isGlobalEnvironment} />
				</SidebarWrapper>
				<ResultView />
			</Grid>
		</>
	);
}

async function save(
	isNewEnvironment: boolean,
	atLeastOneNonAdminTeamExists: boolean,
	configToSave: EnvironmentConfig,
): Promise<EnvironmentVO | undefined> {
	const atLeastOneTeamAssigned = configToSave.teamIds.length > 0;
	if (
		atLeastOneNonAdminTeamExists &&
		!atLeastOneTeamAssigned &&
		(await userConfirm({
			title: 'Your environment is only assigned to the administrators team',
			message: (
				<>
					Environments are best when also assigned to a non-administrator team to enable them to do Chaos Engineering
					safely and autonomously. So, we recommend assigning it to other teams.
				</>
			),
			actions: [
				{ value: 'cancel', label: 'Let me fix this' },
				{ value: 'confirm', label: 'Save anyway', variant: 'primary' },
			],
			width: '720px',
		})) !== 'confirm'
	) {
		return;
	}

	if (!configToSave.name) {
		const inputResult = await userInput({
			title: 'Name your Environment',
			placeholder: 'Untitled Environment',
			buttonOkCaption: isNewEnvironment ? 'Create Environment' : 'Save Environment',
		});
		if (inputResult.action === 'cancel') {
			return;
		}
		configToSave = { ...configToSave, name: inputResult.input || '' };
	}

	try {
		if (isEnvironmentVO(configToSave)) {
			// configToSave = { ...configToSave, version: configToSave.version + 1 };
			await Services.environments.updateEnvironment(configToSave.id, configToSave);
			return configToSave;
		}
		return await Services.environments.createEnvironment(configToSave);
	} catch (error) {
		console.error('error', error);
		Snackbar.error(error.title);
	}
}

function isEnvironmentVO(environment: EnvironmentConfig): environment is EnvironmentVO {
	return environment !== undefined && 'id' in environment;
}
