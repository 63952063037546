/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import StripChart, { colors, DataPoint, StripType } from 'components/StripChart/StripChart';
import { Colors, Flex, Pill, presets, Text } from '@steadybit/ui-components-lib';
import { useExecutionMetrics } from 'services/executionMetricsStreams';
import { DataStreamResult } from 'utils/hooks/stream/result';
import { localeCompareIgnoreCase } from 'utils/string';
import { ReactElement, useState } from 'react';
import { Stack } from 'components';

import { ExperimentRunLogsAndMetricsProps } from '../experimentExecutionLogsAndMetrics';
import StripChartTime from '../widgets/StripChartTime';
import ChartWithTimeAxis from './ChartWithTimeAxis';
import LoadingContent from './LoadingContent';
import EmptyContent from './EmptyContent';

type DeploymentReplicaCountMetricsProps = Pick<
	ExperimentRunLogsAndMetricsProps,
	'duration' | 'start' | 'position' | 'experimentExecution' | 'progress'
>;

export default function DeploymentReplicaCountMetrics({
	experimentExecution,
	progress,
	position,
	duration,
	start,
}: DeploymentReplicaCountMetricsProps): ReactElement {
	const groupLimit = 5;
	const liveUpdate = !experimentExecution.ended;

	const [userCheckedGroups, setUserCheckedGroups] = useState<Set<string> | null>(null);

	const groupedMetrics = useGroupedExecutionMetrics();
	if (!groupedMetrics.value?.length) {
		return (
			<Flex align="center" justify="center" style={{ height: '100%' }}>
				{liveUpdate || groupedMetrics.loading ? (
					<LoadingContent loading={groupedMetrics.loading}>Waiting for metrics...</LoadingContent>
				) : (
					<EmptyContent>No metrics recorded.</EmptyContent>
				)}
			</Flex>
		);
	}

	const groupedMetricsWithDeviations = groupedMetrics.value
		.filter((metricGroup) => metricGroup.deviations > 0)
		.sort((a, b) => b.deviations - a.deviations);

	if (groupedMetricsWithDeviations.length === 0) {
		return (
			<EmptyContent>
				<Flex direction="horizontal" spacing="xxSmall">
					<Text type="medium">All</Text>
					<Text type="mediumStrong" style={{ color: Colors.neutral700 }}>
						{groupedMetrics.value.length}
					</Text>
					<Text type="medium">deployments have as many pods ready as desired.</Text>
				</Flex>
			</EmptyContent>
		);
	}

	const renderedGroups = !userCheckedGroups
		? groupedMetricsWithDeviations.slice(0, groupLimit)
		: groupedMetricsWithDeviations.filter((mg) => userCheckedGroups.has(mg.id));

	const stripsForChart: StripType[] = renderedGroups.map((mg) => {
		const lastValue = mg.values[mg.values.length - 1];
		const row = {
			label: mg.deployment,
			longLabel: `${mg.namespace}/${mg.deployment}`,
			descriptor: `${lastValue.replicas_ready_count}/${lastValue.replicas_desired_count}`,
			dataPoints: mg.values.map((value) => {
				return {
					timestamp: value.timestamp,
					endTimestamp: value.timestamp,
					values: {
						replicas_desired_count: value.replicas_desired_count || 0,
						replicas_ready_count: value.replicas_ready_count || 0,
						replicas_current_count: value.replicas_current_count || 0,
					},
				};
			}),
		};
		row.dataPoints.forEach((dataPoint, i) => {
			dataPoint.endTimestamp = row.dataPoints[i + 1]?.timestamp ?? progress ?? experimentExecution.ended;
		});
		return row;
	});

	if (stripsForChart.length < groupLimit) {
		const numStripFills = groupLimit - stripsForChart.length;
		for (let i = 0; i < numStripFills; i++) {
			stripsForChart.unshift({
				label: '',
				descriptor: '',
				dataPoints: [],
			});
		}
	}

	return (
		<Stack p="small" pt="xSmall" height="100%" justifyContent="space-between" size="xSmall">
			<Flex direction="horizontal" spacing="xxxSmall">
				<Text type="smallStrong">{groupedMetricsWithDeviations.length}</Text>
				<Text type="small" style={{ color: Colors.neutral600 }}>
					out of
				</Text>
				<Text type="smallStrong">{groupedMetrics.value.length}</Text>
				<Text type="small" style={{ color: Colors.neutral600 }}>
					deployments have deviating pods ready
				</Text>
			</Flex>

			{groupedMetricsWithDeviations.length > 0 && (
				<presets.dropdown.MultiChoiceButton
					items={groupedMetricsWithDeviations
						.sort((a, b) => localeCompareIgnoreCase(a.id, b.id))
						.map((mg) => ({
							id: mg.id,
							label: `${mg.namespace}/${mg.deployment}`,
						}))}
					selectedIds={renderedGroups.map((mg) => mg.id)}
					maxSelections={groupLimit}
					placeholder="Please select deployments"
					onCheck={(id) => {
						const newChecked = new Set(renderedGroups.map((mg) => mg.id));
						newChecked.add(id);
						setUserCheckedGroups(newChecked);
					}}
					onUncheck={(id) => {
						const newChecked = new Set(renderedGroups.map((mg) => mg.id));
						newChecked.delete(id);
						setUserCheckedGroups(newChecked);
					}}
					style={{ width: '100%' }}
				/>
			)}

			<ChartWithTimeAxis position={position} start={start} duration={duration}>
				<StripChart
					strips={stripsForChart}
					fromTimestamp={start}
					toTimestamp={start + duration}
					getColor={(dataPoint): string => {
						return dataPoint.values.replicas_ready_count < dataPoint.values.replicas_desired_count
							? colors.info
							: dataPoint.values.replicas_current_count > dataPoint.values.replicas_desired_count
								? colors.info
								: colors.success;
					}}
					getTooltipContent={(strip, dataPoint) => <TooltipContent strip={strip} dataPoint={dataPoint} />}
				/>
			</ChartWithTimeAxis>
		</Stack>
	);
}

interface TooltipContentProps {
	strip: StripType;
	dataPoint: DataPoint;
}

function TooltipContent({ strip, dataPoint }: TooltipContentProps): ReactElement {
	const longLabel: string = strip.longLabel as string;
	return (
		<Flex spacing="xSmall" style={{ px: 'small', py: 'xSmall' }}>
			<presets.pill.Tag small>{longLabel}</presets.pill.Tag>

			<StripChartTime dataPoint={dataPoint} />

			{dataPoint.values.replicas_desired_count > dataPoint.values.replicas_ready_count ? (
				<Flex direction="horizontal" align="center" justify="spread" spacing="xSmall">
					<Text type="small">Missing pods:</Text>
					<Pill withLeftIcon="info" style={{ height: '24px' }}>
						{dataPoint.values.replicas_desired_count - dataPoint.values.replicas_ready_count}
					</Pill>
				</Flex>
			) : (
				<Flex align="center" justify="spread">
					<Text type="small">
						Ready: {dataPoint.values.replicas_ready_count}/{dataPoint.values.replicas_current_count}
					</Text>
					<Text type="small"> Desired: {dataPoint.values.replicas_desired_count}</Text>
				</Flex>
			)}
		</Flex>
	);
}

interface GroupMetricValue {
	timestamp: number;
	replicas_current_count?: number;
	replicas_desired_count?: number;
	replicas_ready_count?: number;
}

interface GroupedMetrics {
	id: string;
	cluster: string;
	namespace: string;
	deployment: string;
	deviations: number;
	values: GroupMetricValue[]; // sorted by timestamp
}

function useGroupedExecutionMetrics(): DataStreamResult<GroupedMetrics[]> {
	const names = ['replicas_desired_count', 'replicas_ready_count', 'replicas_current_count'];
	return useExecutionMetrics({
		dependencies: names,
		filterMetric: (metric) => names.includes(metric.labels.name),
		transformResponse: (_metrics) =>
			_metrics
				.reduce((acc, m) => {
					const cluster = m.labels['k8s.cluster-name'];
					const namespace = m.labels['k8s.namespace'];
					const deployment = m.labels['k8s.deployment'];
					const groupKey = cluster + '👾' + namespace + '👾' + deployment;
					const ts = m.ts;

					const existing = acc.get(groupKey);
					if (existing) {
						existing.values.push({
							timestamp: ts,
							[m.labels.name]: m.value,
						});
					} else {
						acc.set(groupKey, {
							id: groupKey,
							cluster,
							namespace,
							deployment,
							deviations: 0, // will be calculated further down
							values: [
								{
									timestamp: ts,
									[m.labels.name]: m.value,
								},
							],
						});
					}
					return acc;
				}, new Map<string, GroupedMetrics>())
				.values()
				.toArray()
				.map((v) => {
					v.values = mergeByTimestamp(v.values).sort((a, b) => a.timestamp - b.timestamp);
					v.deviations = v.values.filter((v) => {
						const ready = v.replicas_ready_count ?? 0;
						const desired = v.replicas_desired_count ?? 0;
						const current = v.replicas_current_count ?? 0;
						return ready < desired || current > desired;
					}).length;
					return v;
				})
				.sort((a, b) => {
					if (a.deviations === b.deviations) {
						return localeCompareIgnoreCase(a.id, b.id);
					}
					return b.deviations - a.deviations;
				}),
	});
}

function mergeByTimestamp(values: GroupMetricValue[]): GroupMetricValue[] {
	const merged = new Map<number, GroupMetricValue>();
	values.forEach((v) => {
		const existing = merged.get(v.timestamp);
		if (existing) {
			merged.set(v.timestamp, { ...existing, ...v });
		} else {
			merged.set(v.timestamp, v);
		}
	});
	return Array.from(merged.values());
}
