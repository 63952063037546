/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { Dropdown, Flex, presets, Text, TextInput } from '@steadybit/ui-components-lib';
import { ReactElement, useMemo, useState } from 'react';
import { includes, localeCompare } from 'utils/string';
import { timeZones } from 'utils/dateFns';

interface TimezoneSelectionProps {
	selectedTimezone: string;
	selectTimezone: (timezone: string) => void;
}

export default function TimezoneSelection({ selectedTimezone, selectTimezone }: TimezoneSelectionProps): ReactElement {
	const [filterQuery, setFilterQuery] = useState('');

	const timezonesOptions = useMemo(
		() =>
			timeZones
				.filter((tz) => includes(tz.timeZone, filterQuery))
				.sort((t1, t2) => localeCompare(t1.timeZone, t2.timeZone))
				.map(({ timeZone, offset }) => ({
					id: timeZone,
					label: `${timeZone.replace('_', ' ')} (UTC ${offset})`,
				})),
		[filterQuery],
	);

	return (
		<Flex spacing="xSmall" style={{ width: '100%' }}>
			<Text type="mediumStrong">Timezone</Text>
			<Dropdown
				renderDropdownContent={({ width, close }) =>
					timezonesOptions.length === 0 ? null : (
						<presets.dropdown.DropdownContentFrame width={width} maxHeight="300px">
							<presets.dropdown.SingleChoiceList
								items={timezonesOptions.map((tz) => ({ ...tz, isSelected: tz.id === selectedTimezone }))}
								withKeyboardNavigation
								batchSize={50}
								onSelect={(id) => {
									setFilterQuery('');
									selectTimezone(id);
									close();
								}}
							/>
						</presets.dropdown.DropdownContentFrame>
					)
				}
			>
				{({ setRefElement, isOpen, setOpen }) => {
					return (
						<TextInput
							ref={setRefElement}
							withRightIcon={isOpen ? 'arrow-drop-up' : 'arrow-drop-down'}
							value={filterQuery}
							placeholder={timezonesOptions.find((tz) => tz.id === selectedTimezone)?.label || selectedTimezone}
							onClick={() => setOpen(true)}
							onRightIconClick={() => setOpen(!isOpen)}
							onChange={setFilterQuery}
						/>
					);
				}}
			</Dropdown>
		</Flex>
	);
}
