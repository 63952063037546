/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { useApplicationHeaderHeight } from 'App/ApplicationHeaderHeight';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import useRefreshingTemplates from 'services/useRefreshingTemplates';
import { createResult } from 'utils/hooks/stream/result';
import { useLicenseFeature } from 'services/licenseApi';
import { presets } from '@steadybit/ui-components-lib';
import { usePromise } from 'utils/hooks/usePromise';
import { ReactElement, useState } from 'react';
import { Services } from 'services/services';
import { Stack } from 'components';
import { useField } from 'formik';

import StepConfigurationSidebar from './StepConfigurationSidebar/StepConfigurationSidebar';
import MissingEnvironmentBlurOverlay from './MissingEnvironmentBlurOverlay';
import useIsExperimentDisabled from './useIsExperimentDisabled';
import useDisabledBodyOverflow from './useDisabledBodyOverflow';
import { EditorSettingsContext } from './useEditorSettings';
import UnsavedChangesPrompt from './UnsavedChangesPrompt';
import ExperimentSubHeader from './ExperimentSubHeader';
import StepsSidebar from './StepsSidebar/StepsSidebar';
import DragAndDropHandler from './DragAndDropHandler';
import ExperimentHeader from './ExperimentHeader';
import { TemplatesContext } from './useTemplates';
import { ActionsContext } from './useActions';
import Workspace from './Workspace/Workspace';
import { ActionVO } from '../../ui-api';

export default function ExperimentEditor(): ReactElement {
	useDisabledBodyOverflow();

	const [{ value: teamId }] = useField<string>('teamId');
	const disabled = useIsExperimentDisabled();

	const templatesEnabled = !!useLicenseFeature('TEMPLATES');
	const templatesResult = templatesEnabled
		? useRefreshingTemplates({
				searchContext: 'NEW_EXPERIMENT',
				pageSize: 1_000_000,
				pathname: '/not-used',
				teamId,
			})
		: createResult({ content: [] });

	const [actions, allActions, loading] = useActions(teamId);

	const applicationHeaderHeight = useApplicationHeaderHeight();
	const height = applicationHeaderHeight + 48 + 80; // experiment header and subheader

	const [dropdownContext] = useState(() => new presets.dropdown.DropdownCloseManagement());

	return (
		<EditorSettingsContext.Provider value={{ mode: 'experiment' }}>
			<ActionsContext.Provider value={{ actions, allActions, loading }}>
				<TemplatesContext.Provider value={{ templates: templatesResult.value?.content || [] }}>
					<UnsavedChangesPrompt />
					<Stack size="none">
						<ExperimentHeader section="design" />
						<ExperimentSubHeader />

						{/* Since items can be dragged from the sidebar to the workspace, the context must be mounted here */}
						<DragAndDropHandler>
							<Stack direction="horizontal" size="none">
								<ErrorBoundary log={(err: string, info: string) => console.error(err, info)}>
									<StepsSidebar height={height} />
								</ErrorBoundary>
								<Workspace height={height} />
							</Stack>
						</DragAndDropHandler>

						{!disabled && <MissingEnvironmentBlurOverlay />}

						<presets.dropdown.DropdownContext key="asd" value={dropdownContext}>
							<StepConfigurationSidebar disabled={disabled} />
						</presets.dropdown.DropdownContext>
					</Stack>
				</TemplatesContext.Provider>
			</ActionsContext.Provider>
		</EditorSettingsContext.Provider>
	);
}

function useActions(teamId: string): [ActionVO[], ActionVO[], boolean] {
	const allActionsResult = usePromise(() => Services.actions.fetchActions(), []);
	const allActions = allActionsResult.value || [];

	const allowedActionIdsResult = usePromise(() => Services.experiments.fetchActionIdsForTeam(teamId), []);
	const allowedActionIds = allowedActionIdsResult.value ?? [];

	const isLoading = allActionsResult.loading || allowedActionIdsResult.loading;

	return [allActions.filter((a) => allowedActionIds.includes(a.id)), allActions, isLoading];
}
