/*
 * Copyright 2024 steadybit GmbH. All rights reserved.
 */

import { smellsLikeEnvVar, smellsLikeTemplatePlaceholder } from 'components';
import { UnitFieldUnit } from 'components/UnitField/UnitField';
import { parseDuration, SECONDS } from 'utils/duration';
import { ReactElement } from 'react';

import { Options } from './Common';
import { Input } from './Integer';

interface DurationProps {
	hasErrors?: boolean;
	disabled: boolean;
	value: string;
	setValue: (value: string) => void;
	units?: string[];
}

export default function Duration({ value, hasErrors, disabled, setValue, units }: DurationProps): ReactElement {
	let unit: UnitFieldUnit = SECONDS;
	let duration: number | string = value;
	try {
		const result = parseDuration(value);
		duration = result.value;
		unit = result.unit;
	} catch {
		// ignore
	}

	let v: string = duration.toString();
	if (smellsLikeEnvVar(value) || smellsLikeTemplatePlaceholder(value)) {
		v = value;
	}

	const defaultOptions = [
		{ id: 'ms', label: 'MilliSec.' },
		{ id: 's', label: 'Seconds' },
		{ id: 'm', label: 'Minutes' },
	];
	let options = defaultOptions;
	if (units && units.length > 0) {
		options = [];
		units.forEach((u) => {
			switch (u) {
				case 'milliseconds':
					options.push({ id: 'ms', label: 'MilliSec.' });
					break;
				case 'seconds':
					options.push({ id: 's', label: 'Seconds' });
					break;
				case 'minutes':
					options.push({ id: 'm', label: 'Minutes' });
					break;
				case 'hours':
					options.push({ id: 'h', label: 'Hours' });
					break;
				case 'days':
					options.push({ id: 'd', label: 'Days' });
					break;
				case 'weeks':
					options.push({ id: 'w', label: 'Weeks' });
					break;
				case 'months':
					options.push({ id: 'M', label: 'Months' });
					break;
			}
		});
		if (options.length === 0) {
			options = defaultOptions;
		}
		// sort options from milliseconds to months
		options.sort((a, b) => {
			const order = ['ms', 's', 'm', 'h', 'd', 'w', 'M'];
			return order.indexOf(a.id) - order.indexOf(b.id);
		});
	}
	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<Input
				value={v}
				onChange={(newDuration) => {
					if (smellsLikeEnvVar(newDuration)) {
						setValue(String(newDuration));
					} else if (smellsLikeTemplatePlaceholder(newDuration)) {
						setValue(String(newDuration));
					} else {
						setValue(newDuration + unit.id);
					}
				}}
				hasErrors={hasErrors}
				disabled={disabled}
				withUnit
			/>
			<Options
				value={unit.id}
				options={options}
				onChange={(v) => {
					setValue(duration + v);
				}}
				disabled={disabled}
			/>
		</div>
	);
}
