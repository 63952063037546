/*
 * Copyright 2025 steadybit GmbH. All rights reserved.
 */

import { Button, Colors, Dropdown, Flex, presets, Text } from '@steadybit/ui-components-lib';
import { EnvironmentSummaryVO } from 'ui-api';
import { ReactElement } from 'react';

interface EnvironmentsProps {
	environments: EnvironmentSummaryVO[];
	selectedEnvironmentIds: string[];
	selectIds: (envIds: string[]) => void;
}

export default function Environments({
	selectedEnvironmentIds,
	environments,
	selectIds,
}: EnvironmentsProps): ReactElement {
	return (
		<Dropdown
			renderDropdownContent={({ width }) => {
				const allSelected = environments.every((e) => selectedEnvironmentIds.includes(e.id));

				return (
					<presets.dropdown.DropdownContentFrame minWidth={width} maxHeight="400px">
						<Flex direction="horizontal" align="center" justify="spread" style={{ pt: 'xSmall', pl: 'small' }}>
							<Text type="small" style={{ color: Colors.neutral600 }}>
								ENVIRONMENTS
							</Text>
							<Button
								type="chromeless"
								size="small"
								style={{
									color: Colors.slate,
									onHover: {
										textDecoration: 'underline',
									},
								}}
								onClick={() =>
									allSelected ? selectIds([]) : selectIds(Array.from(new Set(environments.map((e) => e.id))))
								}
							>
								{allSelected ? 'Clear all' : 'Select all'}
							</Button>
						</Flex>
						<presets.dropdown.MultipleChoiceList
							items={environments.map((e) => ({
								id: e.id,
								label: e.name,
								isSelected: selectedEnvironmentIds.includes(e.id),
							}))}
							onUncheck={(id) => selectIds(selectedEnvironmentIds.filter((i) => i !== id))}
							onCheck={(id) => selectIds([...selectedEnvironmentIds, id])}
						/>
					</presets.dropdown.DropdownContentFrame>
				);
			}}
		>
			{({ setRefElement, isOpen, setOpen }) => (
				<presets.dropdown.ChoiceButton
					ref={setRefElement}
					placeholder="Any environment"
					isOpen={isOpen}
					style={{ width: '100%' }}
					onClick={() => setOpen(!isOpen)}
				>
					{environments
						.filter((env) => selectedEnvironmentIds.includes(env.id))
						.map((env) => env.name)
						.join(', ')}
				</presets.dropdown.ChoiceButton>
			)}
		</Dropdown>
	);
}
